import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledBox } from "../controls/StyledBox";

export default function DailyReportMobile(props) {
  const { jobs, expenses, totals } = props;

  return (
    <StyledBox border={0}>
      <Grid container>
        {jobs[0] && jobs[0].id && (
          <>
            <Grid item xs={12} textAlign={"center"} padding={1}>
              <Typography variant="h6">Collection</Typography>
              <Typography variant="body">Total Vehicles: {jobs.length}</Typography>
            </Grid>
            <Grid item xs={12} padding={2}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover>
                      <TableCell sx={{ width: 50 }}>Vehicle</TableCell>
                      <TableCell sx={{ width: 50 }}>Service</TableCell>
                      <TableCell sx={{ width: 75 }}>Amount</TableCell>
                      <TableCell sx={{ width: 50 }}>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.map((job) => (
                      <TableRow key={job.id} hover>
                        <TableCell>{job.vehicle}</TableCell>
                        <TableCell>
                          {job.service_category.split(" ")[0]}
                        </TableCell>
                        <TableCell>
                          ₹ {job.total && job.total.toLocaleString("en-IN")}
                        </TableCell>
                        <TableCell>{job.duration}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>TOTAL</TableCell>
                      <TableCell />
                      <TableCell>
                        ₹ {totals.collection.toLocaleString("en-IN")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} textAlign={"center"} padding={1}>
              <Typography variant="h6">Expense</Typography>
            </Grid>
            <Grid item xs={12} padding={2}>
              <TableContainer component={Paper}>
                <Table size="small" padding="normal">
                  <TableHead>
                    <TableRow hover>
                      <TableCell sx={{ width: 200 }}>Expense</TableCell>
                      <TableCell sx={{ width: 50 }}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expenses.map((expense) => (
                      <TableRow key={expense.id} hover>
                        <TableCell>{expense.description}</TableCell>
                        <TableCell>
                          ₹{" "}
                          {expense.amount &&
                            expense.amount.toLocaleString("en-IN")}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableCell>TOTAL</TableCell>
                    <TableCell>₹ {totals.expense}</TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow hover>
                      <TableCell>
                        <Typography variant="h6">Total Collection</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          ₹ {totals.collection.toLocaleString("en-IN")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>
                        <Typography variant="h6">Total Expense</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          ₹ {totals.expense.toLocaleString("en-IN")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>
                        <Typography variant="h6">Nett Collection</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          ₹{" "}
                          {(totals.collection - totals.expense).toLocaleString(
                            "en-IN"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        {jobs.length === 0 && <Typography>No Data found</Typography>}
      </Grid>
    </StyledBox>
  );
}
