import React from "react";
import { StyledBox } from "../controls/StyledBox";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const JobCardPrint = React.forwardRef((props,ref) => {

const {values, test} = props;


const marginTop = "30px";
const marginRight = "5px";
const marginBottom = "10px";
const marginLeft = "5px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <StyledBox>
        <Grid container>
          <Grid item xs={12} textAlign={"center"} marginBottom={1}>
            <Typography variant="h3" fontWeight={"bold"}>
              Star Automobiles
            </Typography>
            <Typography variant="h5">Suzuki Service Center</Typography>
            <Typography variant="body">
              Shop 1, Duncan Road, Nagpada, Mumbai 400008 <br />
            </Typography>
            <Typography variant="body">
              Tel: +919137953440 Email: starsuzukimumbai@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            textAlign={"center"}
            marginBottom={1}
            marginTop={1}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              Job Card Invoice
            </Typography>
          </Grid>
          <Grid item xs={4} marginBottom={1}>
            <Typography variant="body">
              Customer: <b>{values.name}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Customer Mobile: <b>{values.mobile}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Vehicle #: <b>{values.vehicle}</b>
            </Typography>
          </Grid>
          <Grid item xs={4} marginBottom={1}>
            <Typography variant="body">
              Job Id: <b>{values.job_id}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Service Type: <b>{values.service_type}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Vehicle KM: <b>{values.vehicle_km}</b>
            </Typography>
          </Grid>
          <Grid item xs={4} marginBottom={1}>
            <Typography variant="body">
              Job Advisor: <b>{values.advisor}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Job Technician: <b>{values.technician}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Vehicle Color: <b>{values.vehicle_color}</b>
            </Typography>
          </Grid>
          <Grid item xs={6} marginBottom={1}>
            Customer Concerns: <b>{values.customer_concern}</b>
          </Grid>
          <Grid item xs={6}>
            Star Diagnosis: <b>{values.diagnosis}</b>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Invoice No: <b>{values.invoice_no}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Payment Mode: <b>{values.payment_mode}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body">
              Job Status: <b>{values.job_status}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={3}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, backgroundColor: "white" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="50" align="center">
                      Sr No
                    </TableCell>
                    <TableCell width="70">Part No</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell width="70">MRP</TableCell>
                    <TableCell width="50" align="center">
                      Quantity
                    </TableCell>
                    <TableCell width="60">Amount</TableCell>
                    <TableCell width="60">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.jobItems &&
                    values.jobItems.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell width="50" align="center">
                          {item.item_seq}
                        </TableCell>
                        <TableCell width="70">{item.part_no}</TableCell>
                        <TableCell>{item.part_name}</TableCell>
                        <TableCell width="70">₹ {item.part_mrp}</TableCell>
                        <TableCell width="25" align="center">
                          {item.part_qty}
                        </TableCell>
                        <TableCell width="60">₹ {item.part_amt}</TableCell>
                        <TableCell width="60">₹ {item.part_total}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6} marginLeft={3} marginTop={20}>
            <Typography variant="body">
              Customer Signature: _________________________{" "}
            </Typography>
          </Grid>
          <Grid item xs={4} marginTop={10} marginLeft={10}>
            <Typography variant="subtitle1" align="center" fontWeight={"bold"}>
              <u>SUMMARY</u>
            </Typography>
            <TableContainer>
              <Table
                padding="normal"
                size="small"
                sx={{ padding: "5", margin: "2px" }}
              >
                <TableBody>
                  <TableRow key="amount">
                    <TableCell width="100" align="center">
                      <Typography variant="inherit"> AMOUNT</Typography>
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.total_amount}
                    </TableCell>
                  </TableRow>
                  <TableRow key="discount">
                    <TableCell width="100" align="center">
                      ADJUSTMENT
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.discount}
                    </TableCell>
                  </TableRow>
                  <TableRow key="total_amt">
                    <TableCell width="100" align="center">
                      TOTAL AMOUNT
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.grand_total}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </StyledBox>
    </div>
  );
})
