import React from "react";
//import * as customerServices from "../../services/customerService";
//import * as jobServices from "../../services/jobService";
import { StyledBox } from "../controls/StyledBox";
import {
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { CustomField } from "../controls/StyledField";
import StyledButton from "../controls/StyledButton";
import * as metadata from "../../utils/metadata";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";

function CustomerDetails(props) {
  const {
    values,
    setvalues,
    handleChange,
    resetForm,
    errors,
    setLoading,
    handleError,
    newAlert,
    setcustAssigned,
  } = props;
  const axiosPrivate = useAxiosPrivate();

  const models = metadata.VEHICLE_MODELS;

  //console.log("models: ", models);

  const findCustomer = async (e) => {
    e.preventDefault();

    console.log("Current state: ", values);

    // check mobile is entered
    if (!values.mobile) {
      console.log("mobile not set");
      //setErrors({ ...errors, mobile: "Enter Mobile No" });
      newAlert("error", "Enter Valid Mobile");
      return;
    }

    // check mobile is valid
    if (values.mobile.length !== 10) {
      //setErrors({ ...errors, mobile: "Invalid Mobile number" });
      newAlert("error", "Invalid Mobile");
      return;
    }

    try {
      //set loading
      setLoading(true);
      //const data = await customerServices.fetchCustomerbyMobile(values.mobile);
      const data = (
        await axiosPrivate.post(metadata.EP_FETCH_CUSTOMERBY_MOBILE, {
          input_type: "mobile",
          search_key: values.mobile,
        })
      ).data;

      setLoading(false);

      if (data.cust_id) {
        // setvalues
        setvalues((values) => ({
          ...values,
          ...data,
          default_mobile: data.mobile,
        }));

        // fetch customer vehicles
        /*
        const custVehicles = await customerServices.fetchCustomerVehicles(
          values.mobile
        );
        */
        const custVehicles = (
          await axiosPrivate.post(metadata.EP_FETCH_CUSTOMER_VEHICLES, {
            mobile: values.mobile,
          })
        ).data;
        //console.log(custVehilces instanceof Array); // vehicles found
        if (custVehicles instanceof Array) {
          setvalues((values) => ({
            ...values,
            ...custVehicles[0], //set first vehicle and model found
            vehicles: custVehicles,
          }));
        } // no vehicle assigned found
      } else {
        console.log("Customer Not found"); // Cust not found
        setvalues((values) => ({ ...values, cust_id: "NEW" }));
        newAlert("info", "Customer Not Found. Create new customer!");
      }

      /*
      // Generate Job Id
      //const jobid = await jobServices.getJobId();
      const jobid = (await axiosPrivate.get(metadata.EP_GET_JOB_ID)).data;
      setvalues((values) => ({ ...values, job_id: jobid.nextval }));
      */
      //console.log(values);
    } catch (error) {
      handleError(error);
    }
  };

  const findVehicle = async (e) => {
    e.preventDefault();

    console.log("Current state: ", values);

    // check mobile is entered
    if (!values.vehicle) {
      console.log("vehicle not set");
      //setErrors({ ...errors, mobile: "Enter Mobile No" });
      newAlert("error", "Enter Valid Vehicle");
      return;
    }

    // check mobile is valid
    if (values.vehicle.length < 6) {
      //setErrors({ ...errors, mobile: "Invalid Mobile number" });
      newAlert("error", "Invalid Vehicle");
      return;
    }

    try {
      //set loading
      setLoading(true);
      //const data = await customerServices.fetchCustomerbyMobile(values.mobile);
      const data = (
        await axiosPrivate.post(metadata.EP_GET_VEHICLE_DETAILS, {
          vehicle: values.vehicle,
        })
      ).data;

      setLoading(false);

      if (data && data.vehicle) {
        //vehicle found
        setvalues((values) => ({
          ...values,
          mobile: data.cust_mobile,
          model: data.model,
          vehicle_color: data.color,
          name: data.name,
          address: data.address,
          email: data.email,
          cust_id: data.cust_id,
          alt_mobile: data.alt_mobile,
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const assignCustomerVehicle = async (
    vehicle,
    model,
    mobile,
    default_mobile,
    vehicle_color
  ) => {
    try {
      //const assignedNo = await customerServices.fetchVehicleAssignment(vehicle);
      const assignedNo = (
        await axiosPrivate.post(metadata.EP_FETCH_VEHICLE_ASSIGNMENT, {
          vehicle: vehicle,
        })
      ).data;

      if (!assignedNo.cust_mobile) {
        console.log("vehicle not assigned to any customer.. ");
        // so assign to customer
        /*
        const assignResult = await customerServices.assignVehicle(
          mobile,
          vehicle,
          model
        );
        */
        const assignResult = (
          await axiosPrivate.post(metadata.EP_ASSIGN_VEHICLE, {
            mobile,
            vehicle,
            model,
            color: vehicle_color,
          })
        ).data;
        console.log("Vehicle assigned to customer! " + assignResult);
        newAlert("success", "Vehicle Assigned to Customer!");
        return true;
      }

      if (
        assignedNo.cust_mobile === mobile ||
        assignedNo.cust_mobile === default_mobile
      ) {
        //to handle mobile no change scenario
        // vehicle already assigned to the number so update info (model, color if applicable)
        console.log("Vehicle already assigned to same customer!");
        const resp = (await axiosPrivate.post(metadata.EP_UPDATE_VEHICLE,{
          vehicle,
          model,
          color: vehicle_color,
        })).data;
        console.log('Vehicle updated');
        return true;
      } else {
        // vehicle assigned to a different customer so send error
        newAlert(
          "error",
          "Vehicle is already assigned to Customer: " + assignedNo.cust_mobile
        );
        return false;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const submitCustomer = async (e) => {
    e.preventDefault();
    console.log("Submit Customer");

    if (values.cust_id && values.cust_id !== "NEW") {
      // Existing customer
      try {
        console.log("existing customer scenario");
        //Assign Vehicle if new
        const vehicleAssigned = await assignCustomerVehicle(
          values.vehicle,
          values.model,
          values.mobile,
          values.default_mobile,
          values.vehicle_color,
        );

        if (!vehicleAssigned) {
          newAlert("error", "Unable to Assign Vehicle to the customer");
          return;
        }

        // Update customer
        setLoading(true);
        /*
        const data = await customerServices.updateCustomer({
          name: values.name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          alt_mobile: values.alt_mobile,
          cust_id: values.cust_id,
        });
        */
        const data = (
          await axiosPrivate.put(metadata.EP_UPDATE_CUSTOMER, {
            name: values.name,
            mobile: values.mobile,
            address: values.address,
            email: values.email,
            alt_mobile: values.alt_mobile,
            cust_id: values.cust_id,
          })
        ).data;
        setLoading(false);
        console.log("update customer response: ", data);

        if (data) {
          console.log("customer updated");
          newAlert("success", "Customer Info updated successfully!");
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      // New customer handling
      console.log("New Customer");
      try {
        // check if vehicle already assigned

        /*
        const assignedNo = await customerServices.fetchVehicleAssignment(
          values.vehicle
        );
        */
        const assignedNo = (
          await axiosPrivate.post(metadata.EP_FETCH_VEHICLE_ASSIGNMENT, {
            vehicle: values.vehicle,
          })
        ).data;

        if (assignedNo.cust_mobile) {
          newAlert(
            "error",
            "Vehicle is already assigned to Customer: " + assignedNo.cust_mobile
          );
          return;
        }

        // Create customer
        /*
        const createData = await customerServices.addCustomer({
          name: values.name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          alt_mobile: values.alt_mobile,
        });
        */
        const createData = (
          await axiosPrivate.post(metadata.EP_ADD_CUSTOMER, {
            name: values.name,
            mobile: values.mobile,
            address: values.address,
            email: values.email,
            alt_mobile: values.alt_mobile,
          })
        ).data;

        if (createData.cust_id) {
          console.log("customer created:");
          console.log("cust_id: " + createData.cust_id);
          //update state
          setvalues((values) => ({
            ...values,
            ...createData,
          }));
        } else {
          //unknown error
          newAlert("error", "Unexpected Error while creating customer!");
          return;
        }

        // assign vehicle
        const vehicleAssigned = await assignCustomerVehicle(
          values.vehicle,
          values.model,
          values.mobile,
          values.vehicle_color
        );

        if (vehicleAssigned) {
          newAlert("success", "Customer Created and Assigned successfully!");
        }
      } catch (error) {
        handleError(error);
      }
    }

    //set customer assigned flag if not in edit mode
    console.log("Edit Mode ==> ", values);
    if (!values.editMode) {
      setcustAssigned(true);
    }
  };

  return (
    <form onSubmit={submitCustomer} id="customer_details">
      <StyledBox border={1}>
        <Grid container>
          <Grid
            item
            xs={10}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={1}
          >
            <Typography variant="h6">Customer Details</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            display={"flex"}
            justifyContent={"center"}
            marginTop={0.5}
          >
            <Typography variant="body">
              {values.cust_id && "Cust Id: " + values.cust_id}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              value={values.mobile || ""}
              error={errors.mobile}
              onChange={handleChange}
              label="Mobile No"
              name="mobile"
              autoComplete="off"
              required
              type="number"
              readOnly={values.readOnly}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={findCustomer}>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} alignContent={'center'}>
            <Autocomplete
              id="cust_vehicle"
              name="vehicle"
              readOnly={values.readOnly}
              value={values.vehicle || ""}
              defaultValue={values.vehicle || ""}
              freeSolo
              size="small"
              onChange={(event, newvalue) => {
                //console.log("updating vehicle to: " + newvalue);
                if (newvalue === null) {
                  newvalue = "";
                }
                setvalues({ ...values, vehicle: newvalue.toUpperCase() });
              }}
              options={values.vehicles.map((option) => option.vehicle)}
              renderInput={(params) => (
                <CustomField
                  {...params}
                  label="Vehicle No"
                  name="vehicle"
                  required
                  //readOnly={values.readOnly}
                  //onChange={handleChange}
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      vehicle: e.target.value.toUpperCase(),
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ marginBottom: '15px'}}>
                        <IconButton
                          onClick={findVehicle}
                          //size="small"
                        >
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Vehicle Model"
              name="model"
              readOnly={values.readOnly}
              select
              required
              onChange={handleChange}
              value={values.model || ""}
              error={errors.model}
            >
              {models.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Customer Name"
              name="name"
              readOnly={values.readOnly}
              required
              onChange={handleChange}
              value={values.name || ""}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
            <CustomField
              label="Alternate Mobile"
              name="alt_mobile"
              onChange={handleChange}
              readOnly={values.readOnly}
              value={values.alt_mobile || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Customer Email"
              type="email"
              name="email"
              readOnly={values.readOnly}
              onChange={handleChange}
              value={values.email || ""}
              error={errors.email}
            />
            <Autocomplete
              id="vehicle_color"
              name="vehicle_color"
              value={values.vehicle_color || ""}
              readOnly={values.readOnly}
              size="small"
              onChange={(event, newvalue) => {
                //console.log("updating vehicle to: " + newvalue);
                setvalues({ ...values, vehicle_color: newvalue });
              }}
              options={metadata.VEHICLE_COLORS}
              renderInput={(params) => (
                <CustomField
                  {...params}
                  value={values.vehicle_color || ""}
                  error={errors.vehicle_color}
                  onChange={handleChange}
                  label="Vehicle Color"
                  name="vehicle_color"
                  readOnly={values.readOnly}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Customer Address"
              name="address"
              required
              multiline
              readOnly={values.readOnly}
              onChange={handleChange}
              value={values.address || ""}
              //maxRows={4}
              rows={3}
            />
          </Grid>

          {!values.readOnly && (
            <Grid item xs={10} justifyContent={"center"} display={"flex"}>
              <StyledButton
                type="submit"
                size="small"
                //onClick={submitCustomer}
                text={values.cust_id ? "Update" : "Submit"}
              />
              <StyledButton
                type="reset"
                size="small"
                onClick={resetForm}
                color="inherit"
                text="Reset"
              />
            </Grid>
          )}
        </Grid>
      </StyledBox>
    </form>
  );
}

export default CustomerDetails;
