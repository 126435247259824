import React from "react";
import { StyledBox } from "../controls/StyledBox";
import { Grid, Typography, MenuItem, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
//import * as purchaseServices from "../../services/purchaseService";
import { CustomField } from "../controls/StyledField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as metadata from "../../utils/metadata";
import StyledButton from "../controls/StyledButton";
//import * as accountServices from "../../services/accountService";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";
import useAuth from "../../hooks/UseAuth";

function ExpenseForm(props) {
  const {
    values,
    setvalues,
    handleChange,
    resetForm,
    handleError,
    newAlert,
    setOpenExpense,
  } = props;

  const [vendors, setvendors] = useState([{}]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  useEffect(() => {
    const fetchVendors = async () => {
      //const vendors = await purchaseServices.fetchVendors();
      const vendors = (await axiosPrivate.get(metadata.EP_FETCH_VENDORS)).data;
      console.log("vendors: ", vendors);
      setvendors(vendors);
    };
    fetchVendors().catch((error) => handleError(error));
  }, []);

    useEffect(() => {
      const setSource = () => {
        console.log("user role => ", auth?.roles);

        if (auth && auth.roles.includes("account")) {
          // set source as company
          console.log("setting company as source");
          setvalues((values) => ({
            ...values,
            payment_source: "COMPANY",
          }));
        } else if (auth.roles.includes("admin")){
          // admin so dont set anything
          

        } else {
          console.log("setting source as workshop");
          setvalues((values) => ({
            ...values,
            payment_source: "WORKSHOP",
          }));
        }
      };

      setSource();
    }, [values.payment_source]);

  const submitJob = async (e) => {
    e.preventDefault();
    console.log("create expense with values: ", values);

    //const expenseDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const expenseRequest = {
      ...values,
      amount: parseInt(values.amount),
      //date: dayjs(values.date).format('YYYY-MM-DD'),
      date: dayjs().format("YYYY-MM-DD"),
      //date: expenseDate,
      purchase_id: null,
    };
    console.log("Expense request: ", expenseRequest);

    try {
      // check if daily account is closed. If yes no expense allowed
      /*
      const dailyCollection = await accountServices.fetchDailyCollection(
        dayjs().format("YYYY-MM-DD")
      );
      */
      const dailyCollection = (
        await axiosPrivate.post(metadata.EP_FETCH_DAILY_COLLECTION, {
          data: dayjs().format("YYYY-MM-DD"),
        })
      ).data;
      const latestDate = dayjs(dailyCollection[0].latest_date).format(
        "YYYY-MM-DD"
      );
      if (latestDate === dayjs().format("YYYY-MM-DD")) {
        console.log("daily collection already completed");
        newAlert(
          "error",
          "Daily accounts already closed for the date! No expenses allowed"
        );
        return;
      }

      //await purchaseServices.createExpense(expenseRequest);
      await axiosPrivate.post(metadata.EP_CREATE_EXPENSE,expenseRequest);
      newAlert("success", "Expense Created Successfully!");

      // update company balance if company expense
      if (values.payment_source === metadata.PS_COMPANY) {
        console.log("Company Expense.. updating balances");
        /*
        await accountServices.updateBalances({
          bank_amount:
            values.payment_mode === "CASH" ? 0 : parseInt(-values.amount),
          cash_amount:
            values.payment_mode === "CASH" ? parseInt(-values.amount) : 0,
        });
        */
        await axiosPrivate.post(metadata.EP_UPDATE_BALANCES, {
          bank_amount:
            values.payment_mode === "CASH" ? 0 : parseInt(-values.amount),
          cash_amount:
            values.payment_mode === "CASH" ? parseInt(-values.amount) : 0,
        });
      }

      resetForm();
      setOpenExpense(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleExpenseType = (e, value) => {
    console.log("expense type to set: ", value);
    if (value) {
      setvalues((values) => ({
        ...values,
        expense_type: value.type,
        expense_category: value.category,
      }));
    }
  };
  return (
    <form onSubmit={submitJob} id="expense">
      <StyledBox border={1}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={1}
          >
            <Typography variant="h6">Expense Details</Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Vendor"
              name="vendor"
              required
              readOnly={values.readOnly}
              value={values.vendor || ""}
              onChange={handleChange}
              select
            >
              {vendors.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={4}>
              <DatePicker
                sx={{ width: "80%", margin: "8px" }}
                label="Expense Date"
                name="date"
                required
                readOnly={values.readOnly}
                //value={values.date || null}
                value={dayjs()}
                onChange={(newvalue) =>
                  setvalues({
                    ...values,
                    date: newvalue,
                  })
                }
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={4}>
            <Autocomplete
              id="expense_type"
              size="small"
              options={metadata.EXPENSE_TYPE}
              getOptionLabel={(option) => option?.type || ""}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                value === "" ||
                option.type === value.type
              }
              onChange={(event, value) => handleExpenseType(event, value)}
              renderInput={(params) => (
                <CustomField
                  {...params}
                  label="Expense Type"
                  name="expense_type"
                  required
                  value={values.expense_type || ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <CustomField
              value={values.description || ""}
              onChange={handleChange}
              label="Expense Description"
              name="description"
              readOnly={values.readOnly}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <CustomField
              value={values.amount || ""}
              onChange={handleChange}
              label="Expense Amount"
              name="amount"
              readOnly={values.readOnly}
              required
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Payment Source"
              name="payment_source"
              required
              readOnly={values.readOnly}
              value={values.payment_source || ""}
              onChange={handleChange}
              select
            >
              {metadata.PAYMENT_SOURCE.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Payment Mode"
              name="payment_mode"
              required
              readOnly={values.readOnly}
              value={values.payment_mode || ""}
              onChange={handleChange}
              select
            >
              {metadata.PAYMENT_MODE.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              value={values.paid_by || ""}
              onChange={handleChange}
              label="Paid by"
              name="paid_by"
              readOnly={values.readOnly}
              required
            />
          </Grid>
          <Grid item xs={10} justifyContent={"center"} display={"flex"}>
            <StyledButton
              type="submit"
              size="small"
              //onClick={submitJob}
              text="Submit"
            />
            <StyledButton
              type="reset"
              size="small"
              onClick={resetForm}
              color="inherit"
              text="Reset"
            />
          </Grid>
        </Grid>
      </StyledBox>
    </form>
  );
}

export default ExpenseForm;
