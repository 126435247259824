import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
  Avatar,
  CardActions,
  Container,
  IconButton,
  Typography,
  Button,
  CardActionArea,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { CustomField } from "../controls/StyledField";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import dayjs from "dayjs";
import CallIcon from "@mui/icons-material/Call";
import BuildIcon from "@mui/icons-material/Build";
import { green, blue, yellow, grey, orange } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EngineeringIcon from "@mui/icons-material/Engineering";
//import * as jobServices from "../../services/jobService";
import { useNavigate } from "react-router-dom";
import * as utils from "../../utils/commonUtils";
import * as metadata from "../../utils/metadata";
import { useForm } from "../../hooks/UseForm";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useAuth from "../../hooks/UseAuth";

const JobContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginLeft: "0px",
  padding: theme.spacing(0.25),
}));

export default function JobCard({ job, handleError, newAlert, setReloadJobs }) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const {auth} = useAuth();

  //dayjs.extend(relativeTime);
  //console.log(dayjs(job.date).fromNow());

  //const category = job.service_category;
  //console.log(category);

  //console.log('jobcard job received => ', job)

  const getAvatarBgColor = (category) =>
    ({
      "PAID SERVICE": green[700],
      "FREE SERVICE": blue[500],
      "AMC": yellow[400],
    }[category] || blue[500]);

    const getBGColor = (status) =>
      ({
        "BILLING COMPLETE": green[50],
        "CHECKED IN": grey[200],
        "REPAIR COMPLETE": yellow[100],
        "TRIAL COMPLETE": orange[100]
      }[status] || yellow[100]);
      

  const getAvatarName = (category) =>
    ({
      "PAID SERVICE": "PS",
      "FREE SERVICE": "FS",
      "AMC": "AM",
    }[category] || "FS");

  const handleJobDetails = async (e) => {
    try {
      //const jobFetchDetails = await jobServices.getJobDetails(job.id);

      // check if allowed to open job details
      if (auth && auth.roles.includes("cre")){

        newAlert("info", "Not Allowed!");
        return;

      }


      const jobFetchDetails = (
        await axiosPrivate.post(metadata.EP_GET_JOB_DETAILS, { job_id: job.id })
      ).data;
      console.log(jobFetchDetails);

      // compose jobs payload
      const jobProps = {
        vehicles: [],
        vehicle: jobFetchDetails.cust_info.vehicle,
        cust_id: jobFetchDetails.cust_info.cust_id,
        model: jobFetchDetails.cust_info.model,
        mobile: jobFetchDetails.cust_info.mobile,
        email: jobFetchDetails.cust_info.email,
        name: jobFetchDetails.cust_info.name,
        address: jobFetchDetails.cust_info.address,
        alt_mobile: jobFetchDetails.cust_info.alt_mobile,
        loading: false,
        //parts: [],
        total_amount: jobFetchDetails.job.amount,
        total_gst: jobFetchDetails.job.gst,
        discount: jobFetchDetails.job.discount,
        grand_total: jobFetchDetails.job.total,
        job_id: jobFetchDetails.job.id,
        service_type: jobFetchDetails.job.service_type,
        advisor: jobFetchDetails.job.advisor,
        technician: jobFetchDetails.job.technician,
        job_status: jobFetchDetails.job.status,
        vehicle_km: jobFetchDetails.job.vehicle_km,
        customer_concern: jobFetchDetails.job.cust_concern,
        diagnosis: jobFetchDetails.job.diagnosis,
        invoice_no: jobFetchDetails.job.invoice_no,
        payment_mode: jobFetchDetails.job.payment_mode,
        jobItems: jobFetchDetails.job_items,
        readOnly: true,
        orig_jobItems: jobFetchDetails.job_items,
        custAssigned: true,
        vehicle_color: jobFetchDetails.job.vehicle_color,
      };

     //console.log('Job Props payload: ', jobProps);

      navigate("/job", { state: { jobProps: jobProps } });
    } catch (error) {
      handleError(error);
    }
  };

  const { useAlert } = useForm;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [jobStatus, setJobStatus] = React.useState(job.status);
  const [jobClosure, setJobClosure] = useState({
    invoice_no: null,
    payment_mode: "",
  });

  const open = Boolean(anchorEl);
  const handleCardMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    handleMenuClose();
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChangeStatus = async () => {
    console.log("new status: ", jobStatus);
    console.log("old status: ", job.status);

    console.log("jobClosure: ", jobClosure);

    if (jobStatus === job.status) {
      setDialogOpen(false);
      return;
    }

    try {
      // set job stage time
      if (jobStatus === metadata.JS_UNDER_REPAIR) {
        //repair started
        /*
        await jobServices.addJobStage({
          job_id: job.id,
          stage: "REPAIR",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
        await axiosPrivate.post(metadata.EP_ADD_JOB_STAGE, {
          job_id: job.id,
          stage: "REPAIR",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
      } else if (jobStatus === metadata.JS_REPAIR_COMPLETE) {
        //repair completed
        /*
        await jobServices.updateJobStageEnd({
          job_id: job.id,
          stage: "REPAIR",
          endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
       await axiosPrivate.post(metadata.EP_UPDATE_JOB_STATE_END, {
         job_id: job.id,
         stage: "REPAIR",
         endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });

        // add start for trial
        /*
        await jobServices.addJobStage({
          job_id: job.id,
          stage: "TRIAL",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
       await axiosPrivate.post(metadata.EP_ADD_JOB_STAGE, {
         job_id: job.id,
         stage: "TRIAL",
         starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });

      } else if (jobStatus === metadata.JS_TRIAL_COMPLETE) {
        //trial completed
        /*
        await jobServices.updateJobStageEnd({
          job_id: job.id,
          stage: "TRIAL",
          endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
       await axiosPrivate.post(metadata.EP_UPDATE_JOB_STATE_END, {
         job_id: job.id,
         stage: "TRIAL",
         endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });

        // set billing start time
        /*
        await jobServices.addJobStage({
          job_id: job.id,
          stage: "BILLING",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
       await axiosPrivate.post(metadata.EP_ADD_JOB_STAGE, {
         job_id: job.id,
         stage: "BILLING",
         starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });
      } else if (jobStatus === metadata.JS_BILLING_COMPLETE) {
        //billing completed

        // first update invoice and mode
        if (jobClosure.invoice_no === "" || jobClosure.payment_mode === "") {
          newAlert("error", "Please enter invoice number and payment mode!");
          return;
        }
        /*
        await jobServices.updateJobPayment({
          job_id: job.id,
          invoice_no: jobClosure.invoice_no,
          payment_mode: jobClosure.payment_mode,
        });
        */
       await axiosPrivate.post(metadata.EP_UPDATE_JOB_PAYMENT, {
         job_id: job.id,
         invoice_no: jobClosure.invoice_no,
         payment_mode: jobClosure.payment_mode,
       });
        console.log("job payment info updated");

        /*
        await jobServices.updateJobStageEnd({
          job_id: job.id,
          stage: "BILLING",
          endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        await jobServices.updateJobStageEnd({
          job_id: job.id,
          stage: "OVERALL",
          endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
       await axiosPrivate.post(metadata.EP_UPDATE_JOB_STATE_END, {
         job_id: job.id,
         stage: "BILLING",
         endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });
       await axiosPrivate.post(metadata.EP_UPDATE_JOB_STATE_END, {
         job_id: job.id,
         stage: "OVERALL",
         endtime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
       });
      }

      // update job status
      /*
      await jobServices.setJobStatus({
        job_id: job.id,
        status: jobStatus,
      });
      */
     await axiosPrivate.post(metadata.EP_SET_JOB_STATUS, {
       job_id: job.id,
       status: jobStatus,
     });

      console.log("Job status updated");
      setDialogOpen(false);
      setReloadJobs((prev) => !prev);
      newAlert("success", "Job status updated successfully!");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div>
      <Card
        variant="elevation"
        elevation={5}
        //sx={{ backgroundColor: "primary.error", color: "primary.contrastText" }}
        sx={{ backgroundColor: getBGColor(job.status) }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: getAvatarBgColor(job.service_category) }}>
              {getAvatarName(job.service_category)}
            </Avatar>
          }
          action={
            <IconButton onClick={handleCardMenu}>
              <GridMoreVertIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: "h6" }}
          title={job.vehicle}
          subheader={
            "Job: " + job.id + " | " + dayjs(job.date).format("DD-MMM-YY h:mm")
          }
        />
        <CardActionArea onClick={handleJobDetails}>
          <CardContent>
            <JobContainer
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <CallIcon color="primary"></CallIcon>
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {job.customer_mobile}
              </Typography>
            </JobContainer>
            <JobContainer>
              <BuildIcon color="primary" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {job.service_type}
              </Typography>
            </JobContainer>
            <JobContainer>
              <ChecklistIcon color="primary" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {job.status}
              </Typography>
            </JobContainer>
            <JobContainer>
              <EngineeringIcon color="primary" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {job.technician}
              </Typography>
            </JobContainer>
            <JobContainer>
              <AccessTimeIcon color="inherit" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {job.duration}
              </Typography>
            </JobContainer>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="medium" onClick={handleJobDetails}>
            JOB CARD
          </Button>
        </CardActions>
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDialogOpen}>Change Status</MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Change Job {job.id} Status</DialogTitle>
        <DialogContent>
          <CustomField
            value={jobStatus || ""}
            onChange={(e) => setJobStatus(e.target.value)}
            label="Set New Status"
            name="new_job_status"
            select
          >
            {utils.getJobStatusOptions(job.status).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </CustomField>
          {jobStatus === metadata.JS_BILLING_COMPLETE && (
            <>
              <CustomField
                label="Invoice #"
                name="invoice_no"
                value={jobClosure.invoice_no || ""}
                type="number"
                required
                onChange={(e) =>
                  setJobClosure((values) => ({
                    ...values,
                    invoice_no: e.target.value,
                  }))
                }
              />
              <CustomField
                label="Payment Mode"
                name="payment_mode"
                value={jobClosure.payment_mode || ""}
                required
                onChange={(e) =>
                  setJobClosure((values) => ({
                    ...values,
                    payment_mode: e.target.value,
                  }))
                }
                select
              >
                {metadata.PAYMENT_MODE.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeStatus}>SUBMIT</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
