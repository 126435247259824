import React, { useEffect, useState } from "react";
//import * as apptServices from "../services/apptService";
import { useForm } from "../hooks/UseForm";
import { StyledBox } from "../components/controls/StyledBox";
import {
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import * as utils from "../utils/commonUtils";
import * as metadata from "../utils/metadata";
import CustomerCallingForm from "../components/appt/CustomerCallingForm";
//import * as customerServices from "../services/customerService";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import dayjs from "dayjs";
import { useLoading, Loading } from "../hooks/UseLoading";
import { darken, lighten, styled } from "@mui/material/styles";



function CallingList() {
  const [callingList, setcallingList] = useState([]);
  const [missedApptList, setmissedApptList] = useState([]);
  const [starCallList, setstarcallList] = useState([]);
  const [appt, setappt] = useState([]);
  const [call, setcall] = useState({});
  const [callDB, setcallDB] = useState(false);
  const { handleError } = useForm();
  const [query, setQuery] = useState("");
  const [tabIndex, settabIndex] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const { startLoading, stopLoading, isloading } = useLoading();

  //console.log('CallingList rendered');

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--SCHEDULED": {
      backgroundColor: getBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode
          ),
        },
      },
    },
    "& .super-app-theme--CUSTOMER": {
      backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode
          ),
        },
      },
    },
    "& .super-app-theme--MISSED": {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode
          ),
        },
      },
    },
    "& .super-app-theme--CANCELLED": {
      backgroundColor: getBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
        },
      },
    },
  }));

  useEffect(() => {
    console.log("callingList useEffect");
    // load calling List
    const fetchCallingList = async () => {
      startLoading();
      //const callList  = await apptServices.getCallBacks();
      const callList = (await axiosPrivate.get(metadata.EP_GET_CALLBACKS)).data;

      console.log("Call backs: ", callList);
      setcallingList(callList);

      //missed appointments
      //const missedAppt = await apptServices.getMissedApptCalls();
      const missedAppt = (
        await axiosPrivate.get(metadata.EP_GET_MISSED_APPT_CALLS)
      ).data;

      // prepare the List
      var missedApptCalls = [];
      for (var i = 0; i < missedAppt.length; i++) {
        missedApptCalls.push({
          ...missedAppt[i],
          next_call: metadata.CT_APPT_MISSED,
          appt_no: missedAppt[i].id,
        });
      }
      console.log("Missed Appt calls: ", missedApptCalls);
      setmissedApptList(missedApptCalls);

      // start Calling List
      //const starCallsResp = await apptServices.getStarApptCalls({
      //  last_visit: 30,
      //  last_call: 10,
      //});

      //console.log("Making post call!!");

      const starCallsResp = (
        await axiosPrivate.post(metadata.EP_GET_STAR_APPT_CALLS, {
          last_visit: 80,
          last_call: 60,
        })
      ).data;

      console.log("starCall Response: ", starCallsResp);
      var starCalls = [];
      // prepare star list
      for (i = 0; i < starCallsResp.length; i++) {
        starCalls.push({
          ...starCallsResp[i],
          next_call: metadata.CT_NEWAPPT,
          service_type: starCallsResp[i].next_service,
          call_source: "STAR",
        });
      }
      console.log("Star calls: ", starCalls);
      setstarcallList(starCalls);

      // Loading appointments
      const apptList = (await axiosPrivate.get(metadata.EP_GETAPPOINTMENT_LIST))
        .data;

      //console.log('apptList original: ', apptList);
      var updatedList = [];
      let appt_status;
      // prepare list
      for (var i = 0; i < apptList.length; i++) {
        if (!apptList[i].status) {

          if (dayjs(apptList[i].date) < dayjs())
            appt_status = "MISSED"
          else 
            appt_status = "SCHEDULED"
        } else
            appt_status = apptList[i].status;
        updatedList.push({
          ...apptList[i],
          //status: apptList[i].status ? apptList[i].status : "SCHEDULED",
          status: appt_status,
          service_category: utils.getServiceCategory(apptList[i].service_type),
          next_call: metadata.CT_APPT_CONFIRM,
        });
      }
      console.log("apptList with change: ", updatedList);
      setappt(updatedList);

      stopLoading();
    };

    fetchCallingList().catch((error) => handleError(error));
  }, [callDB]);

  const columns = [
    {
      field: "name",
      headerName: "Customer",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "last_visit",
      headerName: "Last Visit",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => utils.formatDate(row.last_visit),
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "next_call",
      headerName: "Call Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "next_service",
      headerName: "Next Service Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call",
      headerName: "CALL",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => newCall(row)}>
          <AddIcCallIcon />
        </IconButton>
      ),
    },
  ];

  const callBackcolumns = [
    {
      field: "name",
      headerName: "Customer",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call_back",
      headerName: "Call Back Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => utils.formatDate(row.call_back),
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "next_call",
      headerName: "Call Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "service_type",
      headerName: "Service Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cre",
      headerName: "CRE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call_source",
      headerName: "SOURCE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call",
      headerName: "CALL",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => newCall(row)}>
          <AddIcCallIcon />
        </IconButton>
      ),
    },
  ];

  const missedApptcolumns = [
    {
      field: "name",
      headerName: "Customer",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "appt_no",
      headerName: "Appt No",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Appt Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => utils.formatDate(row.date),
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "next_call",
      headerName: "Call Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "service_type",
      headerName: "Service Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cre",
      headerName: "CRE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call_source",
      headerName: "SOURCE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call",
      headerName: "CALL",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => newCall(row)}>
          <AddIcCallIcon />
        </IconButton>
      ),
    },
  ];

  const apptcolumns = [
    {
      field: "name",
      headerName: "Customer",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "Appt No",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Appt Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => utils.formatDate(row.date),
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "model",
      headerName: "Model",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "service_type",
      headerName: "Service Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cre",
      headerName: "CRE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "call_source",
      headerName: "SOURCE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "next_call",
      headerName: "Call Type",
      width: 0,
      align: "center",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "call",
      headerName: "CALL",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => newCall(row)}>
          <AddIcCallIcon />
        </IconButton>
      ),
    },
  ];

  const newCall = async (row) => {
    console.log("Action called on the row: ", row);

    var custDetails = {};
    var apptDetails = {};

    try {
      // fetch customer details
      //custDetails = await customerServices.getCustomerbyId(row.cust_id);
      custDetails = (
        await axiosPrivate.post(metadata.EP_GET_CUSTOMERBY_ID, {
          cust_id: row.cust_id,
        })
      ).data;
      console.log("custDetails: ", custDetails);

      // fetch appt details if exists
      if (row.appt_no && row.app_no !== "") {
        //apptDetails = await apptServices.getAppointmentDetails({
        //  appt_no: row.appt_no,
        //});
        apptDetails = (
          await axiosPrivate.post(metadata.EP_GET_APPOINTMENT_DETAILS, {
            appt_no: row.appt_no,
          })
        ).data;
        console.log("apptDetails: ", apptDetails);
      }
    } catch (error) {
      handleError(error);
    }

    //set call details
    setcall({
      mobile: custDetails.mobile,
      model: row.model,
      vehicle: row.vehicle,
      name: custDetails.name,
      cust_id: custDetails.cust_id,
      email: custDetails.email,
      address: custDetails.address,
      call_type: row.next_call,
      service_type: row.service_type,
      appt_no: row.appt_no,
      appt_date: apptDetails.date && dayjs(apptDetails.date),
      call_source: row.call_source ? row.call_source : "STAR", //this needs to be updated!!
    });

    setcallDB(true);
  };

  const filteredList = (list) => {
    if (!query) {
      return list;
    }

    //console.log("query: ", query);
    //console.log("list: ", list);

    return list.filter(
      (item) =>
        item.vehicle?.includes(query) ||
        item.model?.includes(query) ||
        item.next_call?.includes(query) ||
        item.service_type?.includes(query) ||
        item.status?.includes(query) ||
        item.cre?.includes(query) ||
        item.mobile?.includes(query)
    );
  };

  const handleTabChange = (event, newValue) => {
    settabIndex(newValue);
  };

  const handleCloseDB = () => {
    console.log("handling calling DB close action");
    // close calling DB
    setcallDB(false);
  };

  return (
    <>
      <StyledBox>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-search"
          label="Search Calls"
          type="search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </StyledBox>
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab
            label={<> Call Backs ({filteredList(callingList).length})</>}
            id="tab-0"
          />
          <Tab
            label={
              <>Missed Appointments ({filteredList(missedApptList).length}) </>
            }
            id="tab-1"
          />
          <Tab
            label={<>Star Calls ({filteredList(starCallList).length})</>}
            id="tab-2"
          />
          <Tab
            label={<>Appointments ({filteredList(appt).length}) </>}
            id="tab-3"
          />
        </Tabs>
      </StyledBox>

      {tabIndex === 0 && (
        <StyledBox border={0}>
          {callingList.length > 0 ? (
            <DataGrid
              rows={filteredList(callingList)}
              columns={callBackcolumns}
              slots={{ toolbar: GridToolbar }}
            />
          ) : (
            <Typography>No Calls!</Typography>
          )}
        </StyledBox>
      )}
      {tabIndex === 1 && (
        <StyledBox border={0}>
          {missedApptList.length > 0 ? (
            <DataGrid
              rows={filteredList(missedApptList)}
              columns={missedApptcolumns}
              rowCount={50}
              slots={{ toolbar: GridToolbar }}
            />
          ) : (
            <Typography>No Calls!</Typography>
          )}
        </StyledBox>
      )}
      {tabIndex === 2 && (
        <StyledBox border={0}>
          <DataGrid
            rows={filteredList(starCallList)}
            columns={columns}
            pageSizeOptions={[50, 100, 150]}
            slots={{ toolbar: GridToolbar }}
          />
        </StyledBox>
      )}

      {tabIndex === 3 && (
        <StyledBox border={0}>
          <StyledDataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  next_call: false,
                },
              },
            }}
            rows={filteredList(appt)}
            columns={apptcolumns}
            pageSizeOptions={[50, 100, 150]}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.status?.split(" ")[0]}`
            }
            slots={{ toolbar: GridToolbar }}
          />
        </StyledBox>
      )}

      <Dialog open={callDB} onClose={() => setcallDB(false)} maxWidth>
        <DialogContent>
          <CustomerCallingForm call={call} handleCloseDB={handleCloseDB} />
        </DialogContent>
      </Dialog>
      <Loading isloading={isloading} />
    </>
  );
}

export default CallingList;
