import { Avatar, Box, Container, CssBaseline, Typography } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { StyledBox } from "../components/controls/StyledBox";
import useAuth from "../hooks/UseAuth";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import { useLoading, Loading } from "../hooks/UseLoading";
//import https from 'https';
//import { Loading } from "../components/controls/Loading";

function Home() {
  const { auth } = useAuth();
  const [quote, setQuote] = useState({});
  const { startLoading, stopLoading, isloading } = useLoading();
  //const agent = new https.Agent({
  //  rejectUnauthorized: false,
  //});
  
  console.log("userAuth Token: ", auth.accessToken);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        startLoading();
        const quoteResp = await axios.get("https://api.quotable.io/random");

        console.log("Quote: ", quoteResp.data);
        

        setQuote({ content: quoteResp?.data?.content, author: quoteResp?.data?.author });
        stopLoading();
      
      } catch (error) {
        console.error(error);
        setQuote({
          content:
            "What lies behind us and what lies before us are small matters compared to what lies within us.",
          author: "Oliver Wendell Holmes Jr.",
        });
        stopLoading();

      }
    };
    fetchQuote().catch((error) => console.error(error));
    
  }, [auth.accessToken]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledBox
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <HomeIcon />
        </Avatar>
        <Typography variant="h5">You are now Logged In!</Typography>
        <Typography variant="h6">
          {auth.fullname} ({auth.roles})
        </Typography>
        {!isloading && (
          <StyledBox border={2} maxWidth sx={{borderColor: 'secondary.main'}}>
            <Typography variant="subtitle1">{quote.content}</Typography>
            <br />
            <Typography variant="caption" sx={{ fontStyle: "italic" }}>
              - {quote.author}
            </Typography>
          </StyledBox>
        )}
      </StyledBox>

      <Loading isloading={isloading} />
    </Container>
  );
}

export default Home;
