import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const Team = () => {
  return (
    <Container id="team" sx={{ py: { xs: 8, sm: 4 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant="h4">Leadership Team</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography textAlign="center" variant="h5" color={"inherit"}>
            Faisal Mohammed
          </Typography>
          <Typography textAlign="center" variant="h5" color={"inherit"}>
            <a href="tel:+91993093014">9930 993014</a>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="center" variant="h5" color={"inherit"}>
            Sarfaraz Memon
          </Typography>
          <Typography textAlign="center" variant="h5" color={"inherit"}>
            <a href="tel:+917869260619">7869 260619</a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Team;
