import React, { useState } from "react";
import { StyledBox } from "../controls/StyledBox";
import { Grid, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StyledButton from "../controls/StyledButton";
import dayjs from "dayjs";
import { useForm } from "../../hooks/UseForm";
//import * as purchaseServices from "../../services/purchaseService";
//import * as jobServices from "../../services/jobService";
//import * as accountServices from "../../services/accountService";
import * as metadata from "../../utils/metadata";
import { CustomField2 } from "../controls/StyledField";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";

function DailyAccountForm() {
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);
  const [reportDate, setreportDate] = useState(null);
  const { values, setvalues, newAlert, handleError } = useForm("");
  const [totals, setTotals] = useState({
    collection: 0,
    expense: 0,
    toCollect: 0,
  });
  const [collection, setCollection] = useState({
    cash: 0,
    gpay: 0,
    total: 0,
    diff: 0,
    vislble: false,
  });
  const [cashflow, setcashflow] = useState({
    company_cash: 0,
    company_bank: 0,
    actual_cash: 0,
    actual_bank: 0,
    visible: false,
  });

  const axiosPrivate = useAxiosPrivate();

  const clearState = () => {
    setreportDate("");
    setvalues("");
    setTotals({
      collection: 0,
      expense: 0,
      toCollect: 0,
    });
    setCollection({
      cash: 0,
      gpay: 0,
      total: 0,
      diff: 0,
      visible: false,
    });
    setcashflow({
      company_cash: 0,
      company_bank: 0,
      actual_cash: 0,
      actual_bank: 0,
      visible: false,
    });
  };

  const fetchDetails = async (e) => {
    e.preventDefault();
    if (reportDate === null) {
      newAlert("error", "Please select the date");
      return;
    }
    console.log("fetching report data for date", reportDate);

    try {
      // fetch existing collections
      //const dailyCollection = await accountServices.fetchDailyCollection(
      //  dayjs(reportDate).format("YYYY-MM-DD")
      //);

      const dailyCollection = (
        await axiosPrivate.post(metadata.EP_FETCH_DAILY_COLLECTION, {
          date: dayjs(reportDate).format("YYYY-MM-DD"),
        })
      ).data;
      console.log("dailyCollection: ", dailyCollection);

      /*
      const dailyCashFlow = await accountServices.getDailyCashFlow(
        dayjs(reportDate).format("YYYY-MM-DD")
      );
      */
      const dailyCashFlow = (
        await axiosPrivate.post(metadata.EP_GET_DAILY_CASHFLOW, {
          date: dayjs(reportDate).format("YYYY-MM-DD"),
        })
      ).data;
      console.log("dailyCashFlow: ", dailyCashFlow);

      // check if daily entry already done
      if (dailyCollection[0].id) {
        console.log("Daily collection already exists!");

        // set values
        setCollection((values) => ({
          ...values,
          cash: dailyCollection[0].received_cash,
          gpay: dailyCollection[0].received_gpay,
          total: dailyCollection[0].received_total,
          visible: true,
        }));

        setTotals((values) => ({
          ...values,
          toCollect: dailyCollection[0].ws_collection,
        }));

        if (dailyCashFlow[0]){
          setcashflow((values) => ({
            ...values,
            company_cash: dailyCashFlow[0].system_cash,
            company_bank: dailyCashFlow[0].system_bank,
            actual_cash: dailyCashFlow[0].actual_cash,
            actual_bank: dailyCashFlow[0].actual_bank,
            visible: true,
          }));

        }
        
        // set readOnly flag
        setvalues((values) => ({
          ...values,
          readOnly: true,
        }));

        newAlert(
          "info",
          "Daily Collection Already completed and cannot be changed"
        );
        return;
      }

      if (dailyCollection[0].latest_date !== null) {
        const latestDate = dayjs(dailyCollection[0].latest_date).format(
          "YYYY-MM-DD"
        );
        console.log("latestDate: ", latestDate);
        const requestDate = dayjs(reportDate).format("YYYY-MM-DD");

        // entry not found. first check if future entry exists so collection not allowed
        if (latestDate && latestDate > requestDate) {
          newAlert("error", "Future collection exists for date " + latestDate);
          return;
        }

        // entry does not exist fetch the records
        /*
      const allExpenses = await purchaseServices.fetchExpenseByDate({
        expense_date: dayjs(reportDate).format("YYYY-MM-DD"),
        payment_source: "WORKSHOP",
      });
      */
      }
      
      const expenseList = (
        await axiosPrivate.post(metadata.EP_FETCH_EXPENSES_BYDATE, {
          expense_date: dayjs(reportDate).format("YYYY-MM-DD"),
          payment_source: "WORKSHOP",
        })
      ).data;

      var totalExpense = 0;
      for (var i = 0; i < expenseList.length; i++) {
        console.log("adding: ", expenseList[i].amount);
        totalExpense += expenseList[i].amount;
      }
      console.log("Expenses list: ", expenseList);
      console.log("Total: ", totalExpense);

      /*
      const allJobs = await jobServices.getJobListByDate({
        job_date: dayjs(reportDate).format("YYYY-MM-DD"),
        job_stage: "OVERALL",
        job_status: metadata.JS_BILLING_COMPLETE,
      });
      */

      const jobList = (await axiosPrivate.post(metadata.EP_GET_JOBLIST_BYDATE, {
        job_date: dayjs(reportDate).format("YYYY-MM-DD"),
        job_stage: "OVERALL",
        job_status: metadata.JS_BILLING_COMPLETE,
      })).data;

      var totalCollection = 0;
      var startTime;
      var endTime;
      var diff;
      for (i = 0; i < jobList.length; i++) {
        console.log("adding: ", jobList[i].total);
        totalCollection += jobList[i].total;
        //calculate duration
        startTime = dayjs(jobList[i].starttime);
        endTime = dayjs(jobList[i].endtime);

        diff = dayjs.duration(endTime.diff(startTime));
        console.log("Duration ==> ", diff.format("HH:mm"));

        jobList[i] = { ...jobList[i], duration: diff.format("HH:mm") };
      }

      console.log("Job list: ", jobList);
      console.log("Collection total: ", totalCollection);

      if (jobList.length === 0) newAlert("info", "No Job data found!");

      // valid case to set entry. turn collection visibility
      setCollection((values) => ({
        ...values,
        visible: true,
      }));

      setTotals((values) => ({
        ...values,
        expense: totalExpense,
        collection: totalCollection,
        toCollect: totalCollection - totalExpense,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleCollection = (e) => {
    var { name, value } = e.target;

    if (value === "") value = 0;

    var newTotal = 0;
    if (name === "cash") newTotal = parseInt(collection.gpay) + parseInt(value);
    else newTotal = parseInt(collection.cash) + parseInt(value);

    console.log("newTotal: ", newTotal);

    setCollection((values) => ({
      ...values,
      [name]: value,
      total: newTotal,
    }));
  };

  const submitCollection = async () => {
    console.log("submitCollection");
    console.log("collection.cash ", collection.cash);
    console.log("collection.gpay ", collection.gpay);
    // validate
    if (collection.cash === 0 && collection.gpay === 0)
      newAlert("error", "Please update CASH and GPAY amount before submitting");

    // check totals
    if (collection.total !== totals.toCollect) {
      newAlert(
        "error",
        "Total collection amount is not matching. Please check again!"
      );
      return;
    }

    try {
      // submit collection
      /*
      await accountServices.addDailyCollection({
        date: dayjs(reportDate).format("YYYY-MM-DD"),
        ws_collection: totals.toCollect,
        received_cash: collection.cash,
        received_gpay: collection.gpay,
        received_total: collection.total,
        diff_amount: 0,
      });
      */
     await axiosPrivate.post(metadata.EP_ADD_DAILY_COLLECTION, {
       date: dayjs(reportDate).format("YYYY-MM-DD"),
       ws_collection: totals.toCollect,
       received_cash: collection.cash,
       received_gpay: collection.gpay,
       received_total: collection.total,
       diff_amount: 0,
     });

      newAlert("success", "Daily Collection Recorded successfully!");

      // update company balance
      /*
      await accountServices.updateBalances({
        bank_amount: collection.gpay,
        cash_amount: collection.cash,
      });
      */
     await axiosPrivate.post(metadata.EP_UPDATE_BALANCES, {
       bank_amount: collection.gpay,
       cash_amount: collection.cash,
     });

      newAlert("success", "Company Balance updated!");

      // fetch new balance
      //const balances = await accountServices.fetchBalances();
      const balances = (await axiosPrivate.get(metadata.EP_FETCH_BALANCES)).data;
      console.log("balances: ", balances);

      setcashflow((values) => ({
        ...values,
        company_bank: balances.bank_total,
        company_cash: balances.cash_total,
        visible: true,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const submitCashTally = async () => {
    console.log("submit cash tally");
    console.log("cashFlow", cashflow);

    //validations
    if (cashflow.company_bank === 0 && cashflow.company_cash === 0) {
      newAlert("error", "System Data is not loaded");
      return;
    }
    if (
      cashflow.company_bank !== cashflow.actual_bank ||
      cashflow.company_cash !== cashflow.actual_cash
    ) {
      newAlert("error", "Cash Tally do not match. Please check");
      return;
    }

    try {
      // update daily cashflow
      /*
      await accountServices.addDailyCashFlow({
        date: dayjs(reportDate).format("YYYY-MM-DD"),
        system_cash: cashflow.company_cash,
        system_bank: cashflow.company_bank,
        actual_cash: cashflow.actual_cash,
        actual_bank: cashflow.actual_bank,
        actual_total: cashflow.actual_bank + cashflow.actual_cash,
        total_diff: 0,
      });
      */

      await axiosPrivate.post(metadata.EP_ADD_DAILY_CASHFLOW, {
        date: dayjs(reportDate).format("YYYY-MM-DD"),
        system_cash: cashflow.company_cash,
        system_bank: cashflow.company_bank,
        actual_cash: cashflow.actual_cash,
        actual_bank: cashflow.actual_bank,
        actual_total: cashflow.actual_bank + cashflow.actual_cash,
        total_diff: 0,
      });

      newAlert("success", "Cash flow updated. Daily cash account closed!");
      clearState();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledBox border={0}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%", margin: "8px" }}
              label="Select Date"
              name="report_date"
              value={reportDate || null}
              required
              onChange={(newvalue) => setreportDate(newvalue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4} marginLeft={3}>
          <StyledButton
            type="submit"
            size="small"
            onClick={fetchDetails}
            text="Fetch"
          />
          <StyledButton
            type="clear"
            size="small"
            onClick={clearState}
            text="Clear"
          />
        </Grid>
        {collection.visible && (
          <>
            <Grid item xs={12} md={10} marginTop={3}>
              <Typography variant="h6" textAlign={"center"}>
                Collection Amount: ₹{" "}
                {totals.toCollect && totals.toCollect.toLocaleString("en-IN")}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={collection.cash || ""}
                onChange={handleCollection}
                label="Cash"
                name="cash"
                readOnly={values.readOnly}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={collection.gpay || ""}
                onChange={handleCollection}
                label="GPAY"
                name="gpay"
                readOnly={values.readOnly}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={collection.total || ""}
                onChange={handleCollection}
                label="Total"
                name="total"
                disabled
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3} marginTop={0} textAlign={"left"}>
              {!values.readOnly && (
                <StyledButton
                  type="submit"
                  size="small"
                  onClick={submitCollection}
                  text="Submit"
                />
              )}
            </Grid>
          </>
        )}
        {cashflow.visible && (
          <>
            <Grid item xs={12} md={10} marginTop={5}>
              <Typography variant="h6" textAlign={"center"}>
                Company Cash Tally
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={cashflow.company_bank || ""}
                label="System Bank"
                name="system_bank"
                disabled
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={cashflow.actual_bank || ""}
                label="Actual Bank"
                name="actual_bank"
                readOnly={values.readOnly}
                type="number"
                required
                onChange={(e) =>
                  setcashflow((values) => ({
                    ...values,
                    actual_bank: parseInt(e.target.value),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={cashflow.company_cash || ""}
                label="System Cash"
                name="system_cash"
                disabled
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={3} marginTop={1} textAlign={"center"}>
              <CustomField2
                value={cashflow.actual_cash || ""}
                label="Actual Cash"
                name="actual_cash"
                type="number"
                readOnly={values.readOnly}
                required
                onChange={(e) =>
                  setcashflow((values) => ({
                    ...values,
                    actual_cash: parseInt(e.target.value),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={3} marginTop={0} textAlign={"left"}>
              {!values.readOnly && (
                <StyledButton
                  type="submit"
                  size="small"
                  onClick={submitCashTally}
                  text="Submit"
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </StyledBox>
  );
}

export default DailyAccountForm;
