import { useForm } from "../hooks/UseForm";
//import * as partServices from "../services/partService";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import CustomerDetails from "../components/customer/CustomerDetails";
import { JobDetails } from "../components/job/JobDetails";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Badge, Fab, Grid, Icon, IconButton, Tab, Tabs } from "@mui/material";
import * as metadata from "../utils/metadata";
import { useState } from "react";
import PageHeader from "../components/layout/PageHeader";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { StyledBox } from "../components/controls/StyledBox";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { JobCardPrint } from "../components/job/JobCardPrint";

const initialValues = {
  vehicles: [],
  vehicle: "",
  model: "",
  mobile: "",
  email: "",
  name: "",
  address: "",
  alt_mobile: "",
  loading: false,
  parts: [],
  total_amount: "",
  total_gst: "",
  discount: "",
  grand_total: "",
  job_id: "",
  service_type: "",
  advisor: "",
  technician: "",
  job_status: "",
  vehicle_km: "",
  customer_concern: "",
  diagnosis: "",
  invoice_no: "",
  jobItems: [
    {
      part_no: "",
      part_name: "",
      part_qty: "",
      part_mrp: "",
      part_amt: "",
      part_gst: "",
      part_total: "",
    },
  ],
  readOnly: false,
};

export default function Job() {
  //console.log("Job page loaded!");
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, settabIndex] = useState(0);
  const axiosPrivate = useAxiosPrivate();

  const {
    values,
    setvalues,
    handleChange,
    resetForm,
    errors,
    setErrors,
    setLoading,
    handleError,
    newAlert,
  } = useForm(initialValues);

  const [partList, setpartList] = useState([{}]);
  const [custAssigned, setcustAssigned] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Customer Job Card",
    content: () => componentRef.current,
  });

  useEffect(() => {
    const fetchParts = async () => {
      //const partListResp = await partServices.fetchParts();

      const partListResp = (await axiosPrivate.get(metadata.EP_FETCH_PARTS))
        .data;
      setvalues((values) => ({ ...values, parts: partListResp }));
      setpartList(partListResp);
      //console.log("Part List set!", partListResp);
    };
    fetchParts().catch((error) => handleError(error));

    // check the props here
    const { state } = location;
    const { jobProps } = state || {};
    console.log("Props received =====> ", jobProps);

    if (jobProps && jobProps.cust_id) {
      // job props received
      console.log("job props received!");

      //set state from jobProps
      setvalues((values) => ({ ...values, ...jobProps }));
      console.log("state set with jobProps!!");
    }
    navigate(".", { replace: true });
    console.log("navigate state is reset!!");
  }, [values.editMode]);

  const handleEdit = () => {
    console.log("handle edit values: ", values);
    if (values.job_status === metadata.JS_BILLING_COMPLETE) {
    }
    setvalues((values) => ({ ...values, readOnly: false, editMode: true }));
  };

  const handleTabChange = (event, newValue) => {
    settabIndex(newValue);
  };

  return (
    <>
      <PageHeader
        title="Customer Job Card"
        subTitle="Please fill the customer details and then assign the job card"
        icon={<IntegrationInstructionsIcon fontSize="medium" />}
      /> 
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container>
          <Grid item xs={10}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Customer Info" id="tab-0" />
              <Tab label="Job Card" id="tab-1" />
            </Tabs>
          </Grid>
            {tabIndex === 1 && (
          <Grid item xs={2} marginTop={1}>
            <IconButton onClick={handlePrint}>
              <PrintIcon color="primary" size="large" />
            </IconButton>
          </Grid>
            )}
        </Grid>
      </StyledBox>
      {tabIndex === 0 && (
        <CustomerDetails
          values={values}
          setvalues={setvalues}
          handleChange={handleChange}
          resetForm={resetForm}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
          handleError={handleError}
          newAlert={newAlert}
          setcustAssigned={setcustAssigned}
        />
      )}

      {tabIndex === 1 && (
        <>
          <JobDetails
            values={values}
            setvalues={setvalues}
            handleChange={handleChange}
            resetForm={resetForm}
            errors={errors}
            setErrors={setErrors}
            setLoading={setLoading}
            handleError={handleError}
            newAlert={newAlert}
            partList={partList}
            custAssigned={custAssigned}
            handlePrint={handlePrint}
          />
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={values.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop>
        <JobCardPrint values={values} test='yusuf' ref={componentRef} />
      </Backdrop>
      {values.readOnly &&
        !(values.job_status === metadata.JS_BILLING_COMPLETE) && (
          <Fab
            color="secondary"
            sx={{ position: "fixed", bottom: 20, right: 20 }}
            onClick={handleEdit}
          >
            <EditIcon></EditIcon>
          </Fab>
        )}
    </>
  );
}
