import React from "react";
import { Grid, Autocomplete, InputAdornment, IconButton, createFilterOptions } from "@mui/material";
import { CustomField2 } from "../controls/StyledField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function PurchaseItem(props) {
  const { values, setvalues, newAlert, purchase_item, index, parts } = props;

  let total_amount = 0;
  let total_gst = 0;
  let grand_total = 0;
  console.log("purchase item received in PurchaseItem: ", purchase_item);
  console.log("parts received: ", parts);

  const handlePurchaseItemChange = (e, value, index) => {
    console.log("handlePI change: ");

    if (value) {
      var purchaseItemtoUpdate = [...values.purchaseItems];
      console.log("purchase item from Purchase Item: ", purchaseItemtoUpdate);
      purchaseItemtoUpdate[index] = {
        part_no: value.part_no,
        part_name: value.part_name,
        part_cost: "",
      };
      console.log("updated purchase items: ", purchaseItemtoUpdate);

      //setpurchaseItems(purchaseItemtoUpdate);
      setvalues((values) => ({
        ...values,
        purchaseItems: purchaseItemtoUpdate,
      }));
    }
  };

  const handleCostChange = (e, index) => {
    var itemsToUpdate = values.purchaseItems;
    console.log("itemsToupdate: ", itemsToUpdate);
    // console.log("purchaseItemtoUpdate: ", purchaseItemtoUpdate);
    itemsToUpdate[index].part_cost = e.target.value;
    console.log("updated purchase item: ", itemsToUpdate);

    setvalues((values) => ({
      ...values,
      purchaseItems: itemsToUpdate,
    }));

    // update totals if Qty is set
    if (itemsToUpdate[index]["part_qty"]) {
      console.log("part qty set updating totals.. ");

      let amount = parseInt(itemsToUpdate[index]["part_qty"]) * e.target.value;
      let gst = 0;
      let total = amount + gst;

      const purchaseItemtoUpdate = [...values.purchaseItems];
      purchaseItemtoUpdate[index] = {
        ...purchaseItemtoUpdate[index],
        part_amt: amount,
        part_gst: gst,
        part_total: total,
      };

      console.log("updated totals pI: ", purchaseItemtoUpdate[index]);

      total_amount = total_gst = grand_total = 0;

      for (let i = 0; i < itemsToUpdate.length; i++) {
        total_amount += parseInt(itemsToUpdate[i].part_total);
        total_gst += parseInt(itemsToUpdate[i].part_gst);
      }
      grand_total = total_amount + total_gst;

      setvalues((values) => ({
        ...values,
        purchaseItems: purchaseItemtoUpdate,
        total_amount: total_amount,
        total_gst: total_gst,
        grand_total: grand_total,
      }));
    }
  };

  const handleRemovePurchaseItem = (index) => {
    console.log("handle remove job item");
    const purchaseItemtoUpdate = [...values.purchaseItems];
    purchaseItemtoUpdate.splice(index, 1);
    //setpurchaseItems(purchaseItemtoUpdate);
    setvalues((values) => ({
      ...values,
      purchaseItems: purchaseItemtoUpdate,
    }));
  };

  const handleAddJobItem = () => {
    console.log("handle add job item");
    const purchaseItemtoUpdate = [
      ...values.purchaseItems,
      {
        part_no: "",
        part_name: "",
        part_qty: "",
        part_cost: "",
        part_amt: "",
        part_gst: "",
        part_total: "",
      },
    ];
    setvalues((values) => ({
      ...values,
      purchaseItems: purchaseItemtoUpdate,
    }));
  };

  const handleQtyChange = (e, index) => {
    console.log("handle qty change");
    const { part_no, part_cost } = values.purchaseItems[index];

    // check part is selected
    if (!part_no || !part_cost) {
      newAlert("error", "Select Part number & Cost first!");
      return;
    }
    console.log(
      "qty update received for: ",
      values.purchaseItems[index],
      "qty: ",
      e.target.value
    );

    let amount = part_cost * e.target.value;
    let gst = 0;
    let total = amount + gst;

    const purchaseItemtoUpdate = [...values.purchaseItems];
    purchaseItemtoUpdate[index] = {
      ...purchaseItemtoUpdate[index],
      part_qty: e.target.value,
      part_amt: amount,
      part_gst: gst,
      part_total: total,
    };

    total_amount = total_gst = grand_total = 0;

    for (let i = 0; i < purchaseItemtoUpdate.length; i++) {
      total_amount += parseInt(purchaseItemtoUpdate[i].part_total);
      total_gst += parseInt(purchaseItemtoUpdate[i].part_gst);
    }
    grand_total = total_amount + total_gst;

    setvalues((values) => ({
      ...values,
      purchaseItems: purchaseItemtoUpdate,
      total_amount: total_amount,
      total_gst: total_gst,
      grand_total: grand_total,
    }));
  };
  const filterJobOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.part_no + option.part_name,
  });

  return (
    <>
      <Grid item xs={2.7} key={index}>
        <Autocomplete
          id={index.toString()}
          size="small"
          options={parts}
          readOnly={values.readOnly}
          value={purchase_item || ""}
          filterOptions={filterJobOptions}
          getOptionLabel={(option) => option?.part_no || ""}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.part_no}>
                {option.part_no} - {option.part_name} - MRP Rs:{" "}
                {option.part_mrp} - Stock: {option.stock}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) =>
            value === undefined ||
            value === "" ||
            option.part_no === value.part_no
          }
          onChange={(event, value) =>
            handlePurchaseItemChange(event, value, index)
          }
          renderInput={(params) => (
            <CustomField2
              {...params}
              label="Part Number"
              name="purchase_item"
              value={purchase_item.part_no || ""}
              readOnly={values.readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomField2
          label="Part Name"
          name="part_name"
          disabled
          value={purchase_item.part_name}
        />
      </Grid>
      <Grid item xs={1}>
        <CustomField2
          label="Cost"
          name="part_cost"
          disabled={Boolean(values.readOnly)}
          onChange={(event) => handleCostChange(event, index)}
          type="number"
          value={purchase_item.part_cost}
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={0.7}>
        <CustomField2
          label="QTY"
          name="part_qty"
          disabled={Boolean(values.readOnly)}
          type="number"
          onChange={(e) => handleQtyChange(e, index)}
          value={purchase_item.part_qty}
        />
      </Grid>
      <Grid item xs={1.2}>
        <CustomField2
          label="Amount"
          name="part_amt"
          type="number"
          disabled
          value={purchase_item.part_amt}
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <CustomField2
          label="GST"
          name="part_gst"
          type="number"
          disabled
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
          value={purchase_item.part_gst}
        />
      </Grid>
      <Grid item xs={1.2}>
        <CustomField2
          label="Total"
          name="part_total"
          type="number"
          disabled
          value={purchase_item.part_total}
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
        />
      </Grid>
      <IconButton
        disabled={values.purchaseItems.length === 1 || values.readOnly}
        onClick={() => handleRemovePurchaseItem(index)}
      >
        {" "}
        <RemoveIcon />
      </IconButton>
      <IconButton disabled={values.readOnly} onClick={() => handleAddJobItem()}>
        {" "}
        <AddIcon />
      </IconButton>
    </>
  );
}

export default PurchaseItem;
