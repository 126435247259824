import React from "react";
import {
  Grid,
  Autocomplete,
  InputAdornment,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import { CustomField2 } from "../controls/StyledField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function JobItem(props) {
  const { values, setvalues, newAlert, job_item, index, partList } = props;

  let total_amount = 0;
  let total_gst = 0;
  let grand_total = 0;

  //console.log("NEW job parts in jobItem: ", partList);
  //console.log("jobItem: ", job_item);

  const handleJobItemChange = (e, value, index) => {
    console.log("handleJob change: ");

    e.preventDefault();

    if (value) {
      var jobItemtoUpdate = [...values.jobItems];
      /*jobItemtoUpdate[index] = {
        part_no: value.part_no,
        part_name: value.part_name,
        part_mrp: value.part_mrp,
      };*/

      jobItemtoUpdate[index] = value;
      //console.log("updated job items: ", jobItemtoUpdate);

      //setjobItems(jobItemtoUpdate);
      setvalues((values) => ({
        ...values,
        jobItems: jobItemtoUpdate,
      }));
    }
  };

  const handleRemoveJobItem = (index) => {
    console.log("handle remove job item");
    const jobItemtoUpdate = [...values.jobItems];
    jobItemtoUpdate.splice(index, 1);
    //setjobItems(jobItemtoUpdate);
    setvalues((values) => ({
      ...values,
      jobItems: jobItemtoUpdate,
    }));
  };

  const handleAddJobItem = () => {
    console.log("handle add job item");
    const jobItemtoUpdate = [
      ...values.jobItems,
      {
        part_no: "",
        part_name: "",
        part_qty: "",
        part_mrp: "",
        part_amt: "",
        part_gst: "",
        part_total: "",
      },
    ];
    setvalues((values) => ({
      ...values,
      jobItems: jobItemtoUpdate,
    }));
  };

  const handleQtyChange = (e, index) => {
    console.log("handle qty change");
    const { part_no, part_mrp } = values.jobItems[index];

    // check part is selected
    if (!part_no) {
      newAlert("error", "Select Part number first!");
      return;
    }
    console.log(
      "qty update received for: ",
      values.jobItems[index],
      "qty: ",
      e.target.value
    );

    let amount = part_mrp * e.target.value;
    let gst = 0;
    let total = amount + gst;

    const jobItemtoUpdate = [...values.jobItems];
    jobItemtoUpdate[index] = {
      ...jobItemtoUpdate[index],
      part_qty: e.target.value,
      part_amt: amount,
      part_gst: gst,
      part_total: total,
    };

    //setjobItems(jobItemtoUpdate);

    // update totals
    //reset totals
    total_amount = total_gst = grand_total = 0;

    for (let i = 0; i < jobItemtoUpdate.length; i++) {
      total_amount += parseInt(jobItemtoUpdate[i].part_total);
      total_gst += parseInt(jobItemtoUpdate[i].part_gst);
    }
    grand_total = total_amount + total_gst;

    setvalues((values) => ({
      ...values,
      jobItems: jobItemtoUpdate,
      total_amount: total_amount,
      total_gst: total_gst,
      grand_total: grand_total,
    }));
  };

  const filterJobOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.part_no + option.part_name,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={3} key={index}>
          <Autocomplete
            id={index.toString()}
            size="small"
            options={partList}
            readOnly={values.readOnly}
            getOptionLabel={(option) => option.part_no || ""}
            filterOptions={filterJobOptions}
            renderOption={(props, option) => {
              //console.log("props: ", props);
              //console.log("option: ", option);
              return (
                <li {...props} key={option.part_no}>
                  {option.part_no} - {option.part_name} - MRP Rs:{" "}
                  {option.part_mrp} - Stock: {option.stock}
                </li>
              );
            }}
            onChange={(event, value) =>
              handleJobItemChange(event, value, index)
            }
            value={job_item || ""}
            isOptionEqualToValue={(option, value) =>
              //console.log("option: ", option) ||
              //console.log("value: ", value) ||
              value === undefined ||
              value === "" ||
              option.part_no === value.part_no
            }
            renderInput={(params) => (
              <CustomField2
                {...params}
                label="Part Number"
                name="job_item"
                value={job_item.part_no || ""}
                readOnly={values.readOnly}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomField2
            label="Part Name"
            name="part_name"
            disabled
            value={job_item.part_name}
          />
        </Grid>

        <Grid item xs={1}>
          <CustomField2
            label="MRP"
            name="part_mrp"
            disabled
            value={job_item.part_mrp}
            
            //InputProps={{
            //  startAdornment: (
            //    <InputAdornment position="start">₹</InputAdornment>
            //  ),
            //}}
          />
        </Grid>
        <Grid item xs={1}>
          <CustomField2
            label="QTY"
            name="part_qty"
            readOnly={values.readOnly}
            type="number"
            onChange={(e) => handleQtyChange(e, index)}
            value={job_item.part_qty}
          />
        </Grid>
        <Grid item xs={1}>
          <CustomField2
            label="Amt"
            name="part_amt"
            type="number"
            disabled
            value={job_item.part_amt}
            //InputProps={{
            //  startAdornment: (
            //    <InputAdornment position="start">₹</InputAdornment>
            //  ),
            //}}
          />
        </Grid>
        {/*
        <Grid item xs={1}>
          <CustomField2
            label="GST"
            name="part_gst"
            type="number"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
            value={job_item.part_gst}
          />
        </Grid>
          */}

        <Grid item xs={1.5}>
          <CustomField2
            label="Total"
            name="part_total"
            type="number"
            disabled
            value={job_item.part_total}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        </Grid>
        {!values.readOnly && (
          <>
            <IconButton
              disabled={values.jobItems.length === 1}
              onClick={() => handleRemoveJobItem(index)}
            >
              {" "}
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={() => handleAddJobItem()}>
              {" "}
              <AddIcon />
            </IconButton>
          </>
        )}
      </Grid>
    </>
  );
}

export default JobItem;
