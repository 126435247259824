import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks/UseForm";
import { StyledBox } from "../controls/StyledBox";
import { Autocomplete, Grid, IconButton, MenuItem } from "@mui/material";
import { CustomField } from "../controls/StyledField";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import * as metadata from "../../utils/metadata";
import * as utils from "../../utils/commonUtils";
//import * as customerServices from "../../services/customerService";
//import * as apptServices from "../../services/apptService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import StyledButton from "../controls/StyledButton";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";
import CustomerHistory from "../customer/CustomerHistory";
import useAuth from "../../hooks/UseAuth";

function CustomerCallingForm(props) {
  const {
    values,
    setvalues,
    resetForm,
    newAlert,
    errors,
    handleChange,
    handleError,
  } = useForm("");

  //console.log('CustomerCallingForm Rendered with props: ', props);
  const { call, handleCloseDB } = props;
  const axiosPrivate = useAxiosPrivate();
  const [visitHistory, setvisitHistory] = useState([]);
  const [callHistory, setcallHistory] = useState([]);
  const { auth } = useAuth();
  //console.log("Auth in CallingForm ==> ", auth);
  //console.log("Values in CallingForm ==> ", values);

  useEffect(() => {
    console.log("customerCallingForm useEffect with call:", call);
    if (call && call.vehicle) {
      console.log("Setting the state values");
      setvalues((values) => ({
        ...values,
        ...call,
        fromStarCalling: true,
      }));
    }
  }, [call && call.vehicle]);

  //useEffect to load history dynamically
  useEffect(() => {
    const loadHistory = async () => {
      //console.log("loading history for: ", values.mobile);

      var cust_calls = [];
      var cust_visits = [];
      // if both values are set
      if (
        values.mobile &&
        values.mobile.length === 10 &&
        values.vehicle &&
        values.vehicle.length > 6
      ) {
        cust_visits = (
          await axiosPrivate.post(
            metadata.EP_CUSTOMER_VISIT_HISTORY_VEHICLE_MOBILE,
            {
              cust_mobile: values.mobile,
              vehicle: values.vehicle,
            }
          )
        ).data;
        console.log("visits: ", cust_visits);

        cust_calls = (
          await axiosPrivate.post(
            metadata.EP_CUSTOMER_CALL_HISTORY_VEHICLE_MOBILE,
            {
              cust_mobile: values.mobile,
              vehicle: values.vehicle,
            }
          )
        ).data;
        console.log("calls: ", cust_calls);
      } else if (values.mobile && values.mobile.length === 10) {
        //mobile set, vehicle not set or invalid
        cust_visits = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_VISIT_HISTORY, {
            cust_mobile: values.mobile,
          })
        ).data;
        console.log("visits: ", cust_visits);

        cust_calls = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_CALL_HISTORY, {
            cust_mobile: values.mobile,
          })
        ).data;
        console.log("calls: ", cust_calls);
      } else if (values.vehicle && values.vehicle.length > 6) {
        // vehicle set, mobile not set or invalid
        cust_visits = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_VISIT_HISTORY_VEHICLE, {
            vehicle: values.vehicle,
          })
        ).data;
        console.log("visits: ", cust_visits);

        cust_calls = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_CALL_HISTORY_VEHICLE, {
            vehicle: values.vehicle,
          })
        ).data;
        console.log("calls: ", cust_calls);
      }

      console.log("setting history!!");
      setcallHistory(cust_calls);
      setvisitHistory(cust_visits);

      /*
      // load from valid mobile
      if (values.mobile && values.mobile.length === 10) {
        console.log("making backend load history from Mobile: ", values.mobile);
        const visits = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_VISIT_HISTORY, {
            cust_mobile: values.mobile,
          })
        ).data;
        console.log("visits: ", visits);

        // check if customer exists and this call is NOT from star calling
        if (!values.fromStarCalling && visits && visits.length > 0) {
          // direct calls not allowed
          
          newAlert(
            "info",
            `${values.mobile} is Existing Star Customer! Must be only called from STAR calling List! `
          );
          
          console.log("Existing Star customer");
          //resetForm();
          //return;
        }

        const calls = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_CALL_HISTORY, {
            cust_mobile: values.mobile,
          })
        ).data;
        console.log("calls: ", calls);

        console.log("setting history!!");
        setcallHistory(calls);
        setvisitHistory(visits);
      }

      // load from vehicle
      if (!values.mobile && values.vehicle && values.vehicle.length > 6) {
        console.log("making backend load history for: ", values.vehicle);

        const visits_vehicle = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_VISIT_HISTORY_VEHICLE, {
            vehicle: values.vehicle,
          })
        ).data;
        console.log("visits: ", visits_vehicle);

        const calls_vehicle = (
          await axiosPrivate.post(metadata.EP_CUSTOMER_CALL_HISTORY_VEHICLE, {
            vehicle: values.vehicle,
          })
        ).data;
        console.log("calls: ", calls_vehicle);

        console.log("setting history from Vehicle!!");
        setcallHistory(calls_vehicle);
        setvisitHistory(visits_vehicle);
      }
      */
    };

    loadHistory().catch((error) => handleError(error));
  }, [values.mobile, values.vehicle]);

  const models = metadata.VEHICLE_MODELS;

  //console.log("serviceType: ", serviceTypes);

  const fetchVehicleAssignment = async () => {
    console.log("checking assignment for vehicle: ", values.vehicle);
    let assignment = null;
    await axiosPrivate
      .post(metadata.EP_FETCH_VEHICLE_ASSIGNMENT, {
        vehicle: values.vehicle,
      })
      .then((response) => {
        assignment = response.data;
      });
    console.log("assignment: ", assignment);

    return assignment;
  };

  function validateCall() {
    // check if the customer vehicle already exists
    //console.log('!fromStar ==> ', !values.fromStarCalling);
    //console.log("!validateVehicle ==> ", !validateVehicle());

    // check mobile is valid
    if (values.mobile.length !== 10) {
      //setErrors({ ...errors, mobile: "Invalid Mobile number" });
      newAlert("error", "Invalid Mobile");
      return false;
    }
    // check dates are set
    if (
      values.call_outcome === metadata.CO_BUSY_CALLBACK ||
      values.call_outcome === metadata.CO_RING_NOANSWER ||
      values.call_outcome === metadata.CO_SERVICE_DONE_OTHERS
    ) {
      // make sure reminder date is set
      if (!values.call_back || values.call_back === "") {
        newAlert("error", "Please set the call back date!");
        return false;
      }
    }

    // check appointment dates are set
    if (
      values.call_outcome === metadata.CO_APPT_BOOKED ||
      values.call_outcome === metadata.CO_APPT_RESCHEDULED
    ) {
      // make sure reminder date is set
      if (!values.appt_date || values.appt_date === "") {
        newAlert("error", "Please set the Appointment date!");
        return false;
      }
      if (!values.call_back || values.call_back === "") {
        newAlert("error", "Please set the call back date!");
        return false;
      }
    }

    // check appointment dates are set
    if (values.call_outcome === metadata.CO_APPT_CONFIRMED) {
      // make sure reminder date is set
      if (!values.appt_date || values.appt_date === "") {
        newAlert("error", "Please set the Appointment date!");
        return false;
      }
    }

    // check NI
    if (values.call_outcome === metadata.CO_NOT_INTERESTED) {
      if (!values.ni_reason || values.ni_reason === "") {
        newAlert("error", "NI Reason must be set for this outcome!");
        return false;
      }
    }

    // check appt_no exists if this is about confirmation
    if (
      values.call_type === metadata.CT_APPT_CONFIRM ||
      values.call_type === metadata.CT_APPT_MISSED
    ) {
      if (!values.appt_no || values.appt_no === "") {
        newAlert("error", "Invalid call type. Appointment Not found!");
        return false;
      }
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("customer calling DB submit");

    const vehicleAssignment = await fetchVehicleAssignment();
    console.log("vehicle assignment in handleSubmit -> ", vehicleAssignment);

    console.log(
      "Appointment date ==> ",
      dayjs(values.appt_date).format("YYYY-MM-DD")
    );
    console.log("Call Back ==> ", values.call_back);

    //return;

    if (
      !values.fromStarCalling &&
      vehicleAssignment &&
      vehicleAssignment.cust_mobile
    ) {
      // existing vehicle trying to call from outside
      newAlert(
        "info",
        `${values.vehicle} is Existing Star Customer assigned to ${vehicleAssignment.cust_mobile}! `
      );
      //console.log("returning false from handleSubmit");
      //return false;
      // Allowing new appointent for exisiting customers
      console.log(
        "Outside call with existing customer. Setting calling source!"
      );
      setvalues((values) => ({
        ...values,
        call_source: "STAR",
      }));
    }

    console.log("proceeding with other form validations...");
    if (!validateCall()) {
      console.log("Call validation failed!!");
      return;
    }

    console.log("submitting call!");
    // set call_type is not part of state
    if (!values.call_type) {
      console.log("setting call type in the state!");
      setvalues((values) => ({
        ...values,
        call_type: metadata.CT_NEWAPPT,
      }));
    }

    try {
      // check customer exists
      //const customer = await customerServices.fetchCustomerbyMobile(
      //  values.mobile
      //);

      const customer = (
        await axiosPrivate.post(metadata.EP_FETCH_CUSTOMERBY_MOBILE, {
          input_type: "mobile",
          search_key: values.mobile,
        })
      ).data;
      var custId = null;
      if (customer.cust_id) {
        //star customer already exist with this mobile, update info

        /*await customerServices.updateCustomer({
          name: values.name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          alt_mobile: null,
          cust_id: customer.cust_id,
        }); */

        await axiosPrivate.put(metadata.EP_UPDATE_CUSTOMER, {
          name: values.name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          alt_mobile: null,
          cust_id: customer.cust_id,
        });
        custId = customer.cust_id;
        console.log("Customer info updated");
      } else {
        //create new customer

        /*const newCustResponse = await customerServices.addCustomer({
          name: values.name,
          mobile: values.mobile,
          address: values.address,
          email: values.email,
          alt_mobile: null,
        }); */

        const newCustResponse = (
          await axiosPrivate.post(metadata.EP_ADD_CUSTOMER, {
            name: values.name,
            mobile: values.mobile,
            address: values.address,
            email: values.email,
            alt_mobile: null,
          })
        ).data;

        custId = newCustResponse.cust_id;
        console.log("New customer created with id: ", custId);
      }

      var apptNumber = values.appt_no;

      // check if appointment to be created or updated
      if (values.call_outcome === metadata.CO_APPT_BOOKED) {
        // new appointment

        /*
        apptNumber = await apptServices.addNewAppointment({
          date: values.appt_date,
          call_source: "STAR",
          cust_id: custId,
          vehicle: values.vehicle,
          service_type: values.service_type,
          cre: "SAMREEN",
          model: values.model,
        }); */
        const apptNumberResp = (
          await axiosPrivate.post(metadata.EP_ADD_NEW_APPOINTMENT, {
            date: dayjs(values.appt_date).format("YYYY-MM-DD"),
            call_source: values.call_source ? values.call_source : "OUTSIDE",
            cust_id: custId,
            vehicle: values.vehicle,
            service_type: values.service_type,
            cre: auth.username ? auth.username : auth.user,
            model: values.model,
          })
        ).data;
        console.log("New appointment created: ", apptNumberResp.id);
        newAlert("info", "New Appointment created: ", apptNumberResp.id);
        apptNumber = apptNumberResp.id;
      }

      if (
        values.call_outcome === metadata.CO_APPT_CONFIRMED ||
        values.call_outcome === metadata.CO_APPT_RESCHEDULED
      ) {
        apptNumber = values.appt_no;

        // make sure appt exists
        if (!apptNumber || apptNumber === "") {
          newAlert(
            "error",
            "Cannot find appointment associated. Pls contact admin"
          );
          return;
        }

        // update appointment date
        /*
        await apptServices.rescheduleAppointment({
          appt_id: values.appt_no,
          new_date: utils.formatDate(values.appt_date),
        });
        */

        await axiosPrivate.post(metadata.EP_RESCHEDULE_APPOINTMENT, {
          appt_id: values.appt_no,
          new_date: utils.formatDate(values.appt_date),
        });
        console.log("Appointment updated");
      }

      // if call type was appointment confirmation or missed appt but customer is not coming back
      if (
        values.call_type === metadata.CT_APPT_CONFIRM ||
        values.call_type === metadata.CT_APPT_MISSED
      ) {
        if (
          values.call_outcome === metadata.CO_SERVICE_DONE_OTHERS ||
          values.call_outcome === metadata.CO_SERVICE_DONE_STAR ||
          values.call_outcome === metadata.CO_NOT_INTERESTED
        ) {
          // set appointment status as cancelled
          //await apptServices.cancelAppointment({ appt_no: apptNumber });
          await axiosPrivate.post(metadata.EP_CANCEL_APPT, {
            appt_no: apptNumber,
          });
        }
      }

      // NI case mark customer inactive
      if (values.call_outcome === metadata.CO_NOT_INTERESTED) {
        /*
        await customerServices.setCustomerInactive({
          cust_mobile: values.mobile,
          vehicle: values.vehicle,
        });
        */
        await axiosPrivate.post(metadata.EP_SET_CUSTOMER_INACTIVE, {
          cust_mobile: values.mobile,
          vehicle: values.vehicle,
        });

        console.log("customer marked as inactive");
      }

      // customer has an appointment but not picking the call so appointment moved to a later date
      if (
        values.call_outcome === metadata.CO_BUSY_CALLBACK ||
        values.call_outcome === metadata.CO_RING_NOANSWER
      ) {
        // first check if appointment exists
        if (apptNumber && apptNumber !== "") {
          console.log("changing appointment date if applicable");
          await axiosPrivate.post(metadata.EP_RESCHEDULE_APPOINTMENT, {
            appt_id: values.appt_no,
            new_date: utils.formatDate(values.appt_date),
          });
          console.log("Appointment updated");
        }
      }
      // make call entry
      /*
      await apptServices.addCustomerCall({
        date: utils.formatDateTime(),
        call_source: "STAR",
        call_type: values.call_type || metadata.CT_NEWAPPT,
        cust_id: custId,
        vehicle: values.vehicle,
        outcome: values.call_outcome,
        call_back: values.call_back ? utils.formatDate(values.call_back) : null,
        service_type: values.service_type,
        cre: "SAMREEN",
        ni_reason: values.ni_reason,
        next_call: utils.getNextCallType(
          values.call_type || metadata.CT_NEWAPPT,
          values.call_outcome
        ),
        model: values.model,
        appt_no: apptNumber,
      });
      */

      //console.log("Auth.user: ", auth.user);
      //console.log("Auth.username: ", auth.username);
      await axiosPrivate.post(metadata.EP_ADD_CUSTOMER_CALL, {
        date: utils.formatDateTime(),
        call_source: values.call_source ? values.call_source : "OUTSIDE",
        call_type: values.call_type || metadata.CT_NEWAPPT,
        cust_id: custId,
        vehicle: values.vehicle,
        outcome: values.call_outcome,
        call_back: values.call_back ? utils.formatDate(values.call_back) : null,
        service_type: values.service_type,
        //cre: auth.username,
        cre: auth.user ? auth.user : auth.username ? auth.username : null,
        ni_reason: values.ni_reason,
        next_call: utils.getNextCallType(
          values.call_type || metadata.CT_NEWAPPT,
          values.call_outcome
        ),
        model: values.model,
        appt_no: apptNumber,
        appt_date: values.appt_date ? utils.formatDate(values.appt_date) : null,
        notes: values.notes,
      });

      console.log("Customer call Created!");
      newAlert("success", "Call recorded successfully!");

      // set last customer call (if exists in star)
      /*
      await customerServices.setCustomerLastCall({
        cust_mobile: values.mobile,
        vehicle: values.vehicle,
        last_call: utils.formatDate(dayjs()),
      });
      */
      await axiosPrivate.post(metadata.EP_SET_CUSTOMER_LAST_CALL, {
        cust_mobile: values.mobile,
        vehicle: values.vehicle,
        last_call: utils.formatDate(dayjs()),
      });

      //reset
      resetForm();

      // go back to callingList
      handleCloseDB && handleCloseDB();
    } catch (error) {
      handleError(error);
    }
  };

  const findCustomer = async () => {
    console.log("Find vehicle assignment");
    //validateVehicle();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <StyledBox>
          <Grid container>
            <Grid item xs={4}>
              <CustomField
                value={values.mobile || ""}
                error={errors.mobile}
                onChange={handleChange}
                label="Mobile No"
                name="mobile"
                required
                type="number"
                readOnly={values.readOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                value={values.vehicle || ""}
                error={errors.vehicle}
                //onChange={handleChange}
                onChange={(e) =>
                  setvalues((values) => ({
                    ...values,
                    vehicle: e.target.value.toUpperCase(),
                  }))
                }
                label="Vehicle No"
                name="vehicle"
                required
                readOnly={values.readOnly}
                /*InputProps={{
                  endAdornment: (
                    <IconButton onClick={findCustomer}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}*/
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Customer Name"
                name="name"
                readOnly={values.readOnly}
                required
                onChange={(e) =>
                  setvalues((values) => ({
                    ...values,
                    name: e.target.value.toUpperCase(),
                  }))
                }
                value={values.name || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Customer Email"
                type="email"
                name="email"
                //required
                readOnly={values.readOnly}
                onChange={handleChange}
                value={values.email || ""}
                error={errors.email}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Customer Address"
                name="address"
                required
                multiline
                readOnly={values.readOnly}
                onChange={handleChange}
                value={values.address || ""}
                //maxRows={4}
                rows={2}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Vehicle Model"
                name="model"
                readOnly={values.readOnly}
                select
                required
                onChange={handleChange}
                value={values.model || ""}
                error={errors.model}
              >
                {models.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Call Type"
                name="call_type"
                required
                readOnly={values.readOnly}
                onChange={handleChange}
                value={values.call_type || metadata.CT_NEWAPPT}
                error={errors.call_type}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="service_type"
                name="service_type"
                value={values.service_type || ""}
                readOnly={values.readOnly}
                size="small"
                onChange={(event, newvalue) => {
                  //console.log("updating vehicle to: " + newvalue);
                  setvalues({ ...values, service_type: newvalue });
                }}
                options={metadata.SERVICE_TYPE}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option === value
                }
                renderInput={(params) => (
                  <CustomField
                    {...params}
                    value={values.service_type || ""}
                    error={errors.service_type}
                    onChange={handleChange}
                    label="Service Type"
                    name="service_type"
                    readOnly={values.readOnly}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                label="Call Outcome"
                name="call_outcome"
                readOnly={values.readOnly}
                select
                required
                onChange={handleChange}
                value={values.call_outcome || ""}
                error={errors.call_outcome}
              >
                {utils
                  .getCallOutcomeOptions(
                    values.call_type || metadata.CT_NEWAPPT
                  )
                  .map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </CustomField>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={4} marginTop={2}>
                <DatePicker
                  sx={{ width: "80%", margin: "8px" }}
                  label="Appointment Date"
                  name="appt_date"
                  readOnly={values.readOnly}
                  value={values.appt_date || null}
                  //value={dayjs(values.appt_date) || null}
                  onChange={(newvalue) =>
                    setvalues({
                      ...values,
                      appt_date: newvalue,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} marginTop={2}>
                <DatePicker
                  sx={{ width: "80%", margin: "8px" }}
                  label="Call Back Date"
                  name="call_back"
                  readOnly={values.readOnly}
                  value={values.call_back || null}
                  //value={dayjs()}
                  onChange={(newvalue) =>
                    setvalues({
                      ...values,
                      call_back: newvalue,
                    })
                  }
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={4} marginTop={2}>
              <CustomField
                label="Not Interested Reason"
                name="ni_reason"
                readOnly={values.readOnly}
                onChange={handleChange}
                value={values.ni_reason || ""}
                error={errors.ni_reason}
                select
              >
                {metadata.NI_REASON.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <CustomField
                label="Call Notes"
                name="notes"
                //required
                multiline
                readOnly={values.readOnly}
                onChange={handleChange}
                value={values.notes || ""}
                //maxRows={4}
                rows={1}
              />
            </Grid>
            <Grid item xs={10} justifyContent={"center"} display={"flex"}>
              <StyledButton
                type="submit"
                size="small"
                //onClick={submitJob}
                text="Submit"
              />
              <StyledButton
                type="reset"
                size="small"
                onClick={resetForm}
                color="inherit"
                text="Reset"
              />
            </Grid>
          </Grid>
        </StyledBox>
      </form>
      {((values?.mobile && values.mobile.length === 10) ||
        (values?.vehicle && values.vehicle.length > 5)) && (
        <CustomerHistory
          visitHistory={visitHistory}
          callHistory={callHistory}
        />
      )}
    </>
  );
}

export default CustomerCallingForm;
