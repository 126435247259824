import JobCard from "../components/job/JobCard";
import { Box, Container, Divider, Fab, Grid, TextField, Pagination } from "@mui/material";
import { useState } from "react";
//import * as jobServices from "../services/jobService";
import { useForm } from "../hooks/UseForm";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import { useLoading, Loading } from "../hooks/UseLoading";
import dayjs from "dayjs";

const initialValues = [{}];

export default function JobList() {
  const { newAlert, handleError } = useForm(initialValues);
  const navigate = useNavigate();

  const [jobs, setjobs] = useState(initialValues);
  const [query, setQuery] = useState("");
  const [reloadJobs, setReloadJobs] = useState(false);

  const [page, setPage] = useState(1);
  const itemsPerPage = 16;
  const [noOfPages, setnoOfPages] = useState(1);

  const { startLoading, stopLoading, isloading } = useLoading();


  const axiosPrivate = useAxiosPrivate();
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  useEffect(() => {
    // load the job;
    const fetchJobs = async () => {
      //const jobList = await jobServices.getJobsList();

      startLoading();
      const jobList = (await axiosPrivate.get(metadata.EP_GET_JOB_LIST)).data;

      console.log('jobList => ', jobList);

      // set duration
      var startTime = "";
      var endTime = "";
      var Totalmins = "";
      var mins = ""
      var hours = "";
      for (var i=0; i<jobList.length;i++){
        //console.log('jobList Item: ', jobList[i]);
        startTime = dayjs(jobList[i].starttime);
        if (!jobList[i].duration){
          endTime = dayjs();
          
          console.log(
            "setting endtime for job =>: ",
            jobList[i].id,
            " to ==>",
            endTime
          );
        
        } else {
          // job completed
          endTime = dayjs(jobList[i].endtime);
          //console.log('setting endtime for job =>: ', jobList[i].id, ' to ==>', endTime);
        }

        Totalmins = endTime.diff(startTime,"minutes", true);
        hours = parseInt(Totalmins / 60);
        mins = dayjs().minute(Totalmins).$m;

        if (jobList[i].starttime === null || !jobList[i].duration)
          jobList[i].duration = "IN PROGRESS";
        else
          jobList[i].duration =
            hours === 0 ? `${mins} minutes` : `${hours} hour, ${mins} minutes`;

      }

      setjobs(jobList);
      setnoOfPages(Math.ceil(jobList.length / itemsPerPage));

      stopLoading();
    };
    //console.log("fetching jobs!!");
    fetchJobs().catch((error) => handleError(error));
  }, [reloadJobs]);

  const keys = [
    //"id",
    "vehicle",
    "customer_mobile",
    "service_category",
    "status",
    "technician",
    "date",
    "advisor",
  ];
 //console.log("vehicle: ", jobs[0]["vehicle"]);

  const filteredJobs = (joblist) => {
    //console.log("filtering data");

    /*
    return data.filter(
      (item) =>
        item.vehicle.includes(query) ||
        item.customer_mobile.includes(query) ||
        item.service_category.includes(query) ||
        item.status.includes(query) ||
        item.technician.includes(query)
    ); */

    if (!query) {
      // Check if the query is empty
      return jobs; // Return all jobs if the query is empty
    }

    return joblist.filter((job) =>
      keys.some((key) => job[key].includes(query))
    );
  };

  const handleNewJob = () => {
    console.log("create new Job!");
    navigate("/job");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Container sx={{ margin: 2 }}>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-search"
          label={<>Search Jobs: ({filteredJobs(jobs).length}) available</>}
          type="search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Container>
      {!isloading && (
        <>
          <Container sx={{ marginTop: 3 }}>
            <Grid container spacing={6}>
              {filteredJobs(jobs)
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((job) => (
                  <Grid key={job.id} item xs={12} sm={6} md={4} lg={3}>
                    <JobCard
                      job={job}
                      handleError={handleError}
                      key={job.id}
                      newAlert={newAlert}
                      setReloadJobs={setReloadJobs}
                    />
                  </Grid>
                ))}
            </Grid>
          </Container>

          <Divider sx={{ marginTop: 4 }} />
          <Container>
            <Pagination
              sx={{ justifyItems: "center", padding: "10px" }}
              count={noOfPages}
              page={page}
              onChange={handlePageChange}
              default={1}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          </Container>
          <Fab
            color="primary"
            sx={{ position: "fixed", bottom: 20, right: 20 }}
            onClick={handleNewJob}
          >
            <AddIcon />
          </Fab>
        </>
      )}
      <Loading isloading={isloading} />
    </>
  );
}
