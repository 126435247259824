import dayjs from "dayjs";
import * as metadata from "./metadata";
import * as stockServices from "../services/stockService";

// format date in YYYY-MM-DD format
export function format(date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getDBTimeStamp(date) {
  return dayjs(date).format("YYYY-MM-DD hh:mm:ss");
}

export function getServiceCategory(service_type) {
  var result = metadata.SERVICE_CATEGORY_MAP.find((obj) => {
    return obj.service_type === service_type;
  });

  return result.service_category;
}

export function getNextServiceType(service_type) {
  var result = metadata.SERVICE_CATEGORY_MAP.find((obj) => {
    return obj.service_type === service_type;
  });

  return result.next_service_type;
}

export function getNextServiceCategory(service_type) {
  var result = metadata.SERVICE_CATEGORY_MAP.find((obj) => {
    return obj.service_type === service_type;
  });

  return result.next_service_category;
}

export function getCallOutcomeOptions(call_type) {
  //var results = metadata.CALL_OUTCOME_TYPE.filter((obj) =>  (obj.callType === call_type));

  //console.log('getOutcomes for: ', call_type);

  var options = [];
  if (!call_type || call_type === "") return options;

  for (var i = 0; i < metadata.CALL_OUTCOME_TYPE.length; i++) {
    if (metadata.CALL_OUTCOME_TYPE[i].callType === call_type) {
      options.push(metadata.CALL_OUTCOME_TYPE[i].callOutcome);
    }
  }

  return options;
}

export function getNextCallType(call_type, call_outcome) {
  console.log('call_type: ', call_type, ' call_outcome: ', call_outcome)
  var result = metadata.CALL_OUTCOME_TYPE.find((obj) => {
    return obj.callType === call_type && obj.callOutcome === call_outcome;
  });

  return result.nextCallType;
}

export function getJobStatusOptions(current_status) {
  //console.log("current status: ", current_status);

  if (!current_status || current_status === "") {
    return ["CHECKED IN"];
  }

  var result = metadata.NEXT_JOB_STATUS.find((obj) => {
    return obj.status === current_status;
  });

  if (!result) {
    return [current_status, "CHECKED IN"];
  }
  //console.log("result: ", result.next_status);
  return result.next_status;
}

export async function updateStock(action, part_no, count) {
   
  try {
    if (action === "ADD")
      await stockServices.addStock({ part_no: part_no, count: count });
    else if (action === "REMOVE")
      await stockServices.removeStock({ part_no: part_no, count: count });
    else if (action === "REDUCE")
      await stockServices.reduceStock({ part_no: part_no, count: count });
    else throw new Error("Invalid Stock Action!");
  } catch (error) {
    throw new Error(`${error.message}`);
  }
}

export function formatDate(date) {
  if (date) return dayjs(date).format("DD-MMM-YYYY");
  else return dayjs().format("YYYY-MM-DD");
}

export function formatDateTime(date) {
  if (date) return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  else return dayjs().format("YYYY-MM-DD HH:mm:ss");
}

// Common function to format numbers as Indian currency in DataGrid
export const formatAsIndianCurrency = (value) => {
  // Ensure the value is a number
  const numberValue = Number(value);

  // Format the number according to the Indian numbering system
  return "₹ " + numberValue.toLocaleString("en-IN", {
    maximumFractionDigits: 0,
  });
};

export function fixMonthlyReport(monthReport) {
  //Fix old reporting data till Apr-2024
  monthReport[monthReport.length - 1].collection = 446092;
  monthReport[monthReport.length - 1].total_exp = 373459;

  monthReport[monthReport.length - 2].collection = 597218;
  monthReport[monthReport.length - 2].total_exp = 432655;

  monthReport[monthReport.length - 3].collection = 415393;
  monthReport[monthReport.length - 3].total_exp = 381040;

  monthReport[monthReport.length - 4].collection = 575196;
  monthReport[monthReport.length - 4].total_exp = 406326;

  monthReport[monthReport.length - 5].collection = 536589;
  monthReport[monthReport.length - 5].total_exp = 425140;

  monthReport[monthReport.length - 6].collection = 562757;
  monthReport[monthReport.length - 6].total_exp = 473467;

  monthReport[monthReport.length - 7].collection = 594805;
  monthReport[monthReport.length - 7].total_exp = 442839;

  monthReport[monthReport.length - 8].collection = 435181;
  monthReport[monthReport.length - 8].total_exp = 433768;

  monthReport[monthReport.length - 9].collection = 535441;
  monthReport[monthReport.length - 9].total_exp = 514673;

  monthReport[monthReport.length - 10].collection = 429241;
  monthReport[monthReport.length - 10].total_exp = 383478;

  monthReport[monthReport.length - 11].collection = 456476;
  monthReport[monthReport.length - 11].total_exp = 421289;

  monthReport[monthReport.length - 12].collection = 707030;
  monthReport[monthReport.length - 12].total_exp = 569097;

  monthReport[monthReport.length - 13].collection = 503661;
  monthReport[monthReport.length - 13].total_exp = 420881;

  monthReport[monthReport.length - 14].collection = 566717;
  monthReport[monthReport.length - 14].total_exp = 567422;

  monthReport[monthReport.length - 15].collection = 620460;
  monthReport[monthReport.length - 15].total_exp = 557982;

  monthReport[monthReport.length - 16].collection = 522435;
  monthReport[monthReport.length - 16].total_exp = 462144;

  monthReport[monthReport.length - 17].collection = 643232;
  monthReport[monthReport.length - 17].total_exp = 529558;

  monthReport[monthReport.length - 18].collection = 654747;
  monthReport[monthReport.length - 18].total_exp = 581412;

  monthReport[monthReport.length - 19].collection = 576668;
  monthReport[monthReport.length - 19].total_exp = 535889;

  monthReport[monthReport.length - 20].collection = 474792;
  monthReport[monthReport.length - 20].total_exp = 409575;

  return monthReport;
}