import React from "react";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import * as utils from "../utils/commonUtils";
import { useLoading } from "../hooks/UseLoading";
import useAuth from "../hooks/UseAuth";
import { useForm } from "../hooks/UseForm";
import { StyledBox } from "../components/controls/StyledBox";
import { Tab, Tabs } from "@mui/material";
import MonthlyWorkshop from "../components/reports/MonthlyWorkshop";

const Reports = () => {
  const axiosPrivate = useAxiosPrivate();
  const { startLoading, stopLoading, isloading } = useLoading();
  const { auth } = useAuth();
  const { values, handleError } = useForm("");
  const [monthlyData, setMonthlyData] = useState([]);
  const [tabIndex, settabIndex] = useState(0);

  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        startLoading();

        let monthReport = (
          await axiosPrivate.post(metadata.EP_FETCH_MONTHLY_REPORT)
        ).data;

        console.log("monthly data: ", monthReport);

        //Fix old reporting data till Apr-2024
        monthReport = utils.fixMonthlyReport(monthReport);

        setMonthlyData(monthReport);
        stopLoading();
      } catch (error) {
        handleError(error);
      }
    };
    fetchMonthlyData().catch((error) => handleError(error));
  }, []);

  const handleTabChange = (event, newValue) => {
    settabIndex(newValue);
  };

  return (
    <>
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Monthly Performance" id="tab-0" />
        </Tabs>
      </StyledBox>

      {tabIndex === 0 && (
        <StyledBox border={0}>
          <MonthlyWorkshop data={monthlyData} />
        </StyledBox>
      )}
    </>
  );
};

export default Reports;
