import React from "react";
import { MenuLayout } from "../components/layout/MenuLayout";
import RequireAuth from "../context/RequireAuth";
import PersistLogin from "../context/PersistLogin";
import { Routes, Route } from "react-router-dom";
import Job from "../pages/Job";
import JobList from "../pages/JobList";
import PurchaseList from "../pages/PurchaseList";
import Expense from "../pages/Expense";
import DailyJobList from "../pages/DailyJobList";
import DailyAccount from "../pages/DailyAccount";
import CustomerCallingDB from "../pages/CustomerCallingDB";
import CallingList from "../pages/CallingList";
import AppointmentList from "../pages/AppointmentList";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Unauthorized from "../pages/Unauthorized";
import Missing from "../pages/Missing";
import Landing from "../pages/Landing";
import MgmtReports from "../pages/MgmtReports";
import Reports from "../pages/Reports";

function App() {
  //console.log("Inside App component!", window.location);
  //console.log("Pathname", window.location.href);
  //console.log('All props: ', window.location);
  return (
    <React.Fragment>
      <header>
        <MenuLayout />
      </header>
      <main>
        <Routes>
          {/* public routes */}
          <Route path="/login" element={<Login />} exact />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Landing />} />

          {/* protected routes routes */}
          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={["admin", "technician", "manager", "advisor"]}
                />
              }
            >
              <Route path="/job" element={<Job />} exact />
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    "cre",
                    "admin",
                    "technician",
                    "manager",
                    "advisor",
                  ]}
                />
              }
            >
              <Route path="/joblist" element={<JobList />} exact />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    "admin",
                    "technician",
                    "manager",
                    "advisor",
                    "account",
                    "cre",
                  ]}
                />
              }
            >
              <Route path="/home" element={<Home />} exact />
              <Route path="/" element={<Home />} exact />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={["admin", "manager", "advisor", "account"]}
                />
              }
            >
              <Route path="/purchaseList" element={<PurchaseList />} exact />
              <Route path="/Expense" element={<Expense />} exact />
              <Route path="/dailyJob" element={<DailyJobList />} exact />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={["admin", "manager", "account"]} />
              }
            >
              <Route path="/dailyAccount" element={<DailyAccount />} exact />
            </Route>

            <Route
              element={<RequireAuth allowedRoles={["admin", "manager"]} />}
            >
              <Route path="/mgmtreports" element={<MgmtReports />} exact />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={["admin", "manager", "cre"]} />
              }
            >
              <Route
                path="/callingDashboard"
                element={<CustomerCallingDB />}
                exact
              />
              <Route path="/callingList" element={<CallingList />} exact />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={["admin", "manager", "cre", "advisor"]}
                />
              }
            >
              <Route
                path="/appointmentList"
                element={<AppointmentList />}
                exact
              />
              <Route
                path="/workshopReports"
                element={<Reports />}
                exact
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={["admin", "manager", "cre", "advisor"]}
                />
              }
            >
              <Route
                path="/appointmentList"
                element={<AppointmentList />}
                exact
              />
            </Route>
          </Route>

          {/* Catch all */}
          <Route path="*" element={<Missing />} />
        </Routes>
      </main>
    </React.Fragment>
  );
}

export default App;
