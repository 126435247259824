import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  margin: theme.spacing(2),
}));

export default function StyledButton(props) {
  const { text, size, color, variant, onClick, ...other } = props;

  return (
    <CustomButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
    >
      {text}
    </CustomButton>
  );
}
