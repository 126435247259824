import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { styled } from "@mui/material/styles";
import CallIcon from "@mui/icons-material/Call";
import BuildIcon from "@mui/icons-material/Build";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LoopSharpIcon from "@mui/icons-material/LoopSharp";
import ContactPhoneRoundedIcon from "@mui/icons-material/ContactPhoneRounded";
import * as metadata from "../../utils/metadata";
//import * as customerServices from "../../services/customerService";
//import * as apptServices from "../../services/apptService";
//import * as utils from "../../utils/commonUtils";
import { blue, green, red, yellow } from "@mui/material/colors";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";
import { useNavigate } from "react-router-dom";

const ApptContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginLeft: "0px",
  padding: theme.spacing(0.3),
}));

function ApptCard(props) {
  const { appt, handleError, newAlert, setcallDB, setcall } =
    props;

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //console.log("loading appt card: ", appt);

  const getAvatarBgColor = (category) =>
    ({
      "PAID SERVICE": green[700],
      "FREE SERVICE": blue[500],
      AMC: yellow[400],
    }[category] || blue[500]);

  const getAvatarName = (category) =>
    ({
      "PAID SERVICE": "PS",
      "FREE SERVICE": "FS",
      AMC: "AM",
    }[category] || "FS");

    const getBGColor = (status) =>
      ({
        "CUSTOMER VISIT": green[50],
        "CANCELLED": red[100],
        "ACTIVE": yellow[100],
      }[status] || yellow[100]);

  const updateApptCall = async (row) => {
    console.log("Action called on the row: ", appt);

    var custDetails = {};
    var apptDetails = {};

    // check if change is allowed:
    if (appt.status === "CANCELLED") {
      newAlert("info", "Cancelled Appointment cannot be updated!");
      return;
    }

    if (appt.status === "CUSTOMER VISIT") {
      newAlert("info", "Completed Appointment cannot be updated!");
      return;
    }

    try {
      // fetch customer details
      //custDetails = await customerServices.getCustomerbyId(appt.cust_id);
      custDetails = (
        await axiosPrivate.post(metadata.EP_GET_CUSTOMERBY_ID, {
          cust_id: appt.cust_id,
        })
      ).data;
      console.log("custDetails: ", custDetails);

      // fetch appt details
      /*
      apptDetails = await apptServices.getAppointmentDetails({
        appt_no: appt.id,
      });
      */
      apptDetails = (
        await axiosPrivate.post(metadata.EP_GET_APPOINTMENT_DETAILS, {
          appt_no: appt.id,
        })
      ).data;

      console.log("apptDetails: ", apptDetails);
    } catch (error) {
      handleError(error);
    }

    //set call details
    setcall({
      mobile: custDetails.mobile,
      model: appt.model,
      vehicle: appt.vehicle,
      name: custDetails.name,
      cust_id: custDetails.cust_id,
      email: custDetails.email,
      address: custDetails.address,
      call_type: metadata.CT_APPT_CONFIRM,
      service_type: appt.service_type,
      appt_no: appt.id,
      appt_date: dayjs(appt.date),
    });

    setcallDB(true);
  };

  const handleNewJob = async () => {
    // compose payload
    const jobProps = {
      vehicles: [],
      vehicle: appt.vehicle,
      cust_id: appt.cust_id,
      model: appt.model,
      mobile: appt.mobile,
      email: appt.email,
      name: appt.name,
      address: appt.address,
      alt_mobile: appt.alt_mobile,
      loading: false,
      //parts: [],
      readOnly: false,
      appt_no: appt.id,
    };

    navigate("/job", { state: { jobProps}});
    

  }

  return (
    <>
      <Card variant="elevation" elevation={4} sx={{ backgroundColor: getBGColor(appt.status)}}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: getAvatarBgColor(appt.service_category) }}>
              {getAvatarName(appt.service_category)}
            </Avatar>
          }
          titleTypographyProps={{ variant: "h6" }}
          title={appt.vehicle}
          subheader={
            "Appt # " + appt.id + " | " + dayjs(appt.date).format("DD-MMM-YY")
          }
        />
        <CardActionArea onClick={updateApptCall}>
          <CardContent>
            <ApptContainer>
              <CallIcon color="primary" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {appt.mobile}
              </Typography>
            </ApptContainer>
            <ApptContainer>
              <BuildIcon color="secondary" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {appt.service_type}
              </Typography>
            </ApptContainer>
            <ApptContainer>
              <TwoWheelerIcon color="inherit" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {appt.model}
              </Typography>
            </ApptContainer>
            <ApptContainer>
              <LoopSharpIcon color="error" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {appt.status ? appt.status : "ACTIVE"}
              </Typography>
            </ApptContainer>
            <ApptContainer>
              <ContactPhoneRoundedIcon color="info" />
              <Typography variant="body2" marginLeft={1} color="text.secondary">
                {appt.cre}
              </Typography>
            </ApptContainer>
          </CardContent>
        </CardActionArea>
        {appt.status==='SCHEDULED' && (
          <CardActions>
            <Button size="medium" onClick={handleNewJob}>
              JOB CARD
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}

export default ApptCard;
