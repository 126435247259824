import { useState, useEffect } from "react";
//import * as purchaseServices from "../services/purchaseService";
import { useForm } from "../hooks/UseForm";
import {
  Container,
  TextField,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  Collapse,
  Box,
  Typography,
  Paper,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import PurchaseForm from "../components/purchase/PurchaseForm";
//import * as partServices from "../services/partService";
import Edit from "@mui/icons-material/Edit";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PurchasePayment from "../components/purchase/PurchasePayment";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import { useLoading, Loading } from "../hooks/UseLoading";

function PurchaseRow(props) {
  const { purchase, setvalues, setOpenPurchase, setOpenPurchasePayment } =
    props;
  const [openPI, setOpenPI] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleEdit = () => {
    console.log("handle edit of Purchase: ", purchase.id);
    console.log("purchaseDetails: ", purchase);

    // set state
    setvalues((values) => ({
      ...values,
      vendor: purchase.vendor,
      order_date: dayjs(purchase.order_date),
      delivery_date: purchase.delivery_date
        ? dayjs(purchase.delivery_date)
        : null,
      purchaseItems: purchase.items,
      discount: purchase.discount,
      total_amount: purchase.amount,
      total_gst: purchase.gst,
      grand_total: purchase.final_amount,
      readOnly: true,
      order_id: purchase.id,
      orig_delivery: purchase.delivery_date
        ? dayjs(purchase.delivery_date)
        : null,
    }));

    setOpenPurchase(true);
  };

  const handlePayment = () => {
    console.log("handle payment: ", purchase.id);

    // set state for payments
    setvalues((values) => ({
      ...values,
      vendor: purchase.vendor,
      order_id: purchase.id,
    }));

    setOpenPurchasePayment(true);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton size="small" onClick={() => setOpenPI(!openPI)}>
            {openPI ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{purchase.id}</TableCell>
        <TableCell align="center">
          {dayjs(purchase.order_date).format("DD-MMM-YYYY")}
        </TableCell>
        <TableCell align="center">
          {purchase.delivery_date &&
            dayjs(purchase.delivery_date).format("DD-MMM-YYYY")}
        </TableCell>
        <TableCell align="center">{purchase.vendor}</TableCell>
        <TableCell align="center">
          ₹{" "}
          {purchase.final_amount &&
            purchase.final_amount.toLocaleString("en-IN")}
        </TableCell>
        <TableCell align="center">
          ₹{" "}
          {purchase.paymentDone && purchase.paymentDone.toLocaleString("en-IN")}
        </TableCell>
        <TableCell align="center">
          ₹ {purchase.balance && purchase.balance.toLocaleString("en-IN")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openPI} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    Purchase Items
                  </Typography>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={1}>
                  <IconButton color="primary" onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton color="secondary" onClick={handlePayment}>
                    <CurrencyRupeeIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Table size="small">
                <TableHead>
                  <TableRow hover>
                    <TableCell>Sequence</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Part Name</TableCell>
                    <TableCell>MRP</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>GST</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchase.items.map((item) => (
                    <TableRow hover key={item.item_seq}>
                      <TableCell>{item.item_seq}</TableCell>
                      <TableCell>{item.part_no}</TableCell>
                      <TableCell>{item.part_name}</TableCell>
                      <TableCell>
                        {item.part_mrp && item.part_mrp.toLocaleString("en-IN")}
                      </TableCell>
                      <TableCell>{item.part_qty}</TableCell>
                      <TableCell>
                        ₹{" "}
                        {item.part_amt && item.part_amt.toLocaleString("en-IN")}
                      </TableCell>
                      <TableCell>
                        ₹{" "}
                        {item.part_gst && item.part_gst.toLocaleString("en-IN")}
                      </TableCell>
                      <TableCell>
                        ₹ {item.part_total.toLocaleString("en-IN")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <br />
            <br />
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    Purchase Payments
                  </Typography>
                </Grid>
                <Grid item xs={8} />
              </Grid>
              {purchase.payments[0] ? (
                <Table size="small">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Mode</TableCell>
                      <TableCell>Paid by</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchase.payments.map((item) => (
                      <TableRow hover key={item.id}>
                        <TableCell>
                          {item.payment_date &&
                            dayjs(item.payment_date).format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell>₹ {item.payment_amount}</TableCell>
                        <TableCell>{item.payment_source}</TableCell>
                        <TableCell>{item.payment_mode}</TableCell>
                        <TableCell>{item.payment_paidby}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography>No Payments Made!</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const initialValues = {
  vendor: "",
  order_id: "",
  order_date: "",
  delivery_date: "",
  purchase_amount: "",
  purchase_gst: "",
  purchase_total: "",
  purchase_discount: "",
  purchaseItems: [
    {
      part_no: "",
      part_name: "",
      part_qty: "",
      part_cost: "",
      part_amt: "",
      part_gst: "",
      part_total: "",
    },
  ],
};

export default function PurchaseList() {
  const [purchaseList, setPurchaseList] = useState([]);
  const [openPurchase, setOpenPurchase] = useState(false);
  const [openPurchasePayment, setOpenPurchasePayment] = useState(false);
  const [query, setQuery] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { values, setvalues, handleChange, resetForm, handleError, newAlert } =
    useForm(initialValues);
     const { startLoading, stopLoading, isloading } = useLoading();

  const handleNewPurchase = () => {
    setOpenPurchase(true);
  };

  const handleClosePurchase = () => {
    // clear state
    setvalues(initialValues);
    setOpenPurchase(false);
  };

  useEffect(() => {
    // load the purchases;
    const fetchPurchases = async () => {

      startLoading();
      //const purchaseList = await purchaseServices.getPurchaseList();

      const purchaseList = (
        await axiosPrivate.get(metadata.EP_GET_PURCHASE_LIST)
      ).data;
      console.log("purchases: ", purchaseList);

      //const purchaseItems = await purchaseServices.getAllPurchaseItemsList();
      const purchaseItems = (await axiosPrivate.get(metadata.EP_GET_ALL_PURCHASE_ITEMS_LIST)).data;
      console.log("purchase items: ", purchaseItems);
      //setPurchaseList(purchaseList);

      //const purchasePayments = await purchaseServices.fetchPayments();
      //const purchasePayments = await purchaseServices.fetchPurchasePayments();
      const purchasePayments = (await axiosPrivate.get(metadata.EP_FETCH_PURCHASE_PAYMENTS)).data;


      console.log("purchase payments: ", purchasePayments);

      // add purchase items to purchase
      let combinedList = [];
      purchaseList.map((purchase, index) => {
        combinedList[index] = purchase;
        combinedList[index]["items"] = purchaseItems.filter(
          (item) => item.purchase_id === purchase.id
        );
        combinedList[index]["payments"] = purchasePayments.filter(
          (item) => item.purchase_id === purchase.id
        );

        // update payment totals
        if (combinedList[index]["payments"]) {
          //console.log('setting paymentDone for index', index);
          var paymentDone = 0;
          for (var i = 0; i < combinedList[index]["payments"].length; i++) {
            paymentDone += combinedList[index]["payments"][i].payment_amount;
          }
        }
        combinedList[index]["paymentDone"] = paymentDone;
        combinedList[index]["balance"] =
          combinedList[index]["final_amount"] - paymentDone;
      });

      console.log("combine list: ", combinedList);
      setPurchaseList(combinedList);
      //console.log('current State: ', values);

      //load parts
      //const partList = await partServices.fetchParts();
      const partList = (await axiosPrivate.get(metadata.EP_FETCH_PARTS)).data;
      setvalues((values) => ({ ...values, parts: partList }));
      console.log("Part List set!", partList);

      //load vendors
      //const vendors = await purchaseServices.fetchVendors();
      const vendors = (await axiosPrivate.get(metadata.EP_FETCH_VENDORS)).data;
      console.log("vendors: ", vendors);
      setvalues((values) => ({ ...values, vendors: vendors }));

      stopLoading();
    };
    fetchPurchases().catch((error) => handleError(error));
  }, [openPurchase, openPurchasePayment]);

  useEffect(() => {
    const fetchParts = async () => {
      
      //const partList = await partServices.fetchParts();
      const partList = (await axiosPrivate.get(metadata.EP_FETCH_PARTS)).data;
      setvalues((values) => ({ ...values, parts: partList }));
      console.log("Part List set!", partList);
    };
    fetchParts().catch((error) => handleError(error));
  }, []);

  const filteredList = (list) => {
    if (!query) {
      return list;
    }

    console.log("query: ", query);
    console.log("purchaseList: ", list);

    return list.filter(
      (item) =>
        item.vendor.includes(query) ||
        item.order_date.includes(query) ||
        (item.items && item.items.some((i) => i.part_name.includes(query))) ||
        (item.items && item.items.some((i) => i.part_no.includes(query)))
      //(item.items.part_no && item.items.part_no.includes(query)) ||
      //  (item.items[0] && item.items[0].part_name.includes(query))
      //item.items.part_name.includes(query)
    );
  };

  return (
    <>
      <Container sx={{ margin: 2 }}>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-search"
          label="Search Purchases"
          type="search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Container>
      <TableContainer sx={{ marginLeft: 2, maxWidth: "95%" }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              hover
              sx={{
                "& th": {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell width="20" />
              <TableCell width="50" align="center">
                ID
              </TableCell>
              <TableCell width="100" align="center">
                Purchase Date
              </TableCell>
              <TableCell width="100" align="center">
                Delivery Date
              </TableCell>
              <TableCell width="80" align="center">
                Vendor
              </TableCell>
              <TableCell width="80" align="center">
                Final Amount
              </TableCell>
              <TableCell width="80" align="center">
                Paid Amount
              </TableCell>
              <TableCell width="50" align="center">
                Balance
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList(purchaseList).map((purchase) => (
              <PurchaseRow
                key={purchase.id}
                purchase={purchase}
                setvalues={setvalues}
                setOpenPurchase={setOpenPurchase}
                setOpenPurchasePayment={setOpenPurchasePayment}
                deliveryDate={purchase.delivery_date}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        onClick={handleNewPurchase}
      >
        <AddIcon />
      </Fab>
      <Dialog open={openPurchase} onClose={handleClosePurchase} fullScreen>
        <DialogContent>
          <PurchaseForm
            values={values}
            setvalues={setvalues}
            handleChange={handleChange}
            resetForm={resetForm}
            handleError={handleError}
            newAlert={newAlert}
            setOpenPurchase={setOpenPurchase}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPurchasePayment}
        onClose={() => setOpenPurchasePayment(false)}
        fullScreen
      >
        <PurchasePayment
          values={values}
          setvalues={setvalues}
          handleError={handleError}
          handleChange={handleChange}
          newAlert={newAlert}
          resetForm={resetForm}
          setOpenPurchasePayment={setOpenPurchasePayment}
        />
      </Dialog>
      <Loading isloading={isloading} />
    </>
  );
}
