import React, { useState } from "react";
import {
  AppBar,
  Tab,
  Toolbar,
  Tabs,
  Drawer,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemIcon,
  ListItemButton,
  Divider,
} from "@mui/material";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { green } from "@mui/material/colors";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentsIcon from "@mui/icons-material/Payments";
import TodayIcon from "@mui/icons-material/Today";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HomeIcon from "@mui/icons-material/Home";
import useAuth from "../../hooks/UseAuth";
import useLogout from "../../hooks/UseLogout";
import AssessmentIcon from "@mui/icons-material/Assessment";

const SyledAppBar = styled(AppBar)(({ theme }) => ({
  //backgroundColor: "#232F3D",
  position: "sticky",
}));

const WelcomeMessage = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flexGrow: 1,
}));

export const MenuLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const logout = useLogout();
  //console.log("Auth in MENU layout ==> ", auth);
  //console.log("Perist in MENU layout ==> ", persist);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const filteredList = (menuList) => {
    var userMenuList = [];

    for (var i = 0; i < menuItems.length; i++) {
      if (menuList[i].roles.find((role) => auth.roles.includes(role))) {
        userMenuList.push(menuList[i]);
      }
    }

    return userMenuList;
  };

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const menuItems = [
    {
      text: "Home",
      icon: <HomeIcon color="primary" />,
      path: "/home",
      roles: ["technician", "advisor", "manager", "admin", "cre", "account"],
    },
    {
      text: "New Job",
      icon: <BuildCircleOutlinedIcon color="secondary" />,
      path: "/job",
      roles: ["technician", "advisor", "manager", "admin"],
    },
    {
      text: "View Jobs",
      icon: <ListAltTwoToneIcon color="inherit" />,
      path: "/joblist",
      roles: ["cre", "technician", "advisor", "manager", "admin"],
    },
    {
      text: "Purchase",
      icon: <ShoppingCartIcon color="error" />,
      path: "/purchaseList",
      roles: ["advisor", "manager", "admin", "account"],
    },
    {
      text: "Expense",
      icon: <PaymentsIcon color="warning" />,
      path: "/Expense",
      roles: ["advisor", "manager", "admin", "account"],
    },
    {
      text: "Daily Report",
      icon: <TodayIcon color="success" />,
      path: "/dailyJob",
      roles: ["advisor", "manager", "admin", "account"],
    },
    {
      text: "Daily Account",
      icon: <AccountBalanceIcon color="info" />,
      path: "/dailyAccount",
      roles: ["manager", "admin", "account"],
    },
    {
      text: "Customer Call",
      icon: <CallIcon color="success" />,
      path: "/callingDashboard",
      roles: ["cre", "manager", "admin"],
    },
    {
      text: "Call List",
      icon: <NotificationsActiveIcon color="error" />,
      path: "/callingList",
      roles: ["cre", "manager", "admin"],
    },
    {
      text: "Appointments",
      icon: <CalendarMonthIcon color="inherit" />,
      path: "/appointmentList",
      roles: ["cre", "manager", "admin", "advisor"],
    },
    {
      text: "Mgmt Reports",
      icon: <AssessmentIcon color="warning" />,
      path: "/mgmtreports",
      roles: ["manager", "admin"],
    },
    {
      text: "Reports",
      icon: <AssessmentIcon color="info" />,
      path: "/workshopReports",
      roles: ["manager", "admin","cre","advisor"],
    },
  ];

  const getAvatarName = (username) => {
    //console.log('username: ', username);
    return username
      .split(" ")
      .map((i) => i.charAt(0))
      .join("");
  };

  return (
    <>
      {auth?.accessToken && (
        <>
          <SyledAppBar>
            <Toolbar>
              <Typography>
                <IconButton color="inherit" size="large" onClick={showDrawer}>
                  <MenuIcon />
                </IconButton>
              </Typography>
              <WelcomeMessage variant="h6">STAR AUTOMOBILE</WelcomeMessage>
              <Avatar sx={{ bgcolor: green[500] }} sizes="medium">
                {getAvatarName(auth.fullname)}
              </Avatar>
              <Typography variant="h7" sx={{ ml: "10px", mr: "10px" }}>
                {auth.fullname}
              </Typography>
              <Typography>
                <IconButton color="inherit" size="small" onClick={signOut}>
                  <LogoutIcon />
                </IconButton>
              </Typography>
            </Toolbar>
          </SyledAppBar>
          <Drawer anchor="left" open={openDrawer} onClose={closeDrawer}>
            <Box
              sx={{ width: 250, bgcolor: "background.paper" }}
              role="presentation"
              onClick={closeDrawer}
              onKeyDown={closeDrawer}
            >
              <List>
                <ListItem key={'header'}>
                  <ListItemText><Typography variant="h5" align="center">STAR MENU</Typography></ListItemText>
                </ListItem>
                {filteredList(menuItems).map((item) => (
                  <>
                    <ListItem
                      key={item.text}
                      onClick={() => navigate(item.path)}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    </ListItem>
                    <Divider component={"li"} />
                  </>
                ))}
              </List>
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};
