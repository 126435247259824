import { useState } from "react";
import { useSnackbar } from "notistack";

export function useForm(initialValues) {
  const initialAlert = {
    show: false,
    severity: "",
    message: "",
  };

  const [values, setvalues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(initialAlert);

  const { enqueueSnackbar } = useSnackbar();

  const newAlert = (severity, message) => {
    enqueueSnackbar(message, {
      variant: severity
    });
  };

  const openAlert = (severity, message) => {
    setAlert({
      show: true,
      severity: severity,
      message: message,
    });
    //console.log("Alert state:")
    //console.log(alert);
  };
  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      show: false,
      severity: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    //console.log('event: ', e);
    const { name, value } = e.target;
    //console.log(name + ": " + value);
    setvalues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setvalues(initialValues);
    setErrors({});
  };

  const setLoading = (prop) => {
    setvalues((values) => ({
      ...values,
      loading: prop,
    }));
  };

  const handleError = (error) => {
    console.log('error object: ', error);
    setLoading(false);
    if (error.message && error.response && error.response.data.message)
      newAlert("error", error.message + ' || Details: ' + error.response.data.message)
    else if (error.message){
      newAlert("error", error.message + ' || Details: Not available');
    }
    else newAlert("error", error.toString());
  }

  return {
    values,
    setvalues,
    handleChange,
    resetForm,
    errors,
    setErrors,
    setLoading,
    alert,
    openAlert,
    setAlert,
    closeAlert,
    handleError,
    newAlert,
  };
}
