import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : "linear-gradient(#02294F, #090E10)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              Star Automobiles Mumbai
            </Typography>
          </Typography>
          <Typography variant="h6" textAlign="center" color="text.secondary">
            Welcome to STAR Automobiles, your trusted two-wheeler service
            partner with over five years of dedicated experience. <br />
            Committed to customer satisfaction, we are an authorized service
            center for TVS and specialized in SUZUKI brands, ensuring quality
            and authenticity. <br />
            Our skilled technicians provide thorough servicing for your valuable
            ride, keeping it in prime condition. <br />
            Choose STAR for a seamless blend of expertise and customer-centric
            services, elevating your two-wheeler experience with excellence.{" "}
            <br /> Trust STAR - where satisfaction drives our commitment.
          </Typography>
          <Typography variant="h4" textAlign={"center"} color="text.primary">
            For Service Appointment Call <br /> TVS:{" "}
            <a href="tel:+918291474788">8291474788</a> SUZUKI:{" "}
            <a href="tel:+919137953440">9137953440</a>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
