import React, { useRef } from "react";
//import * as jobServices from "../../services/jobService";
//import * as apptServices from "../../services/apptService";
//import * as customerServices from "../../services/customerService";
import {
  Grid,
  Typography,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Autocomplete,
  Paper,
  TableHead,
} from "@mui/material";
import { StyledBox } from "../controls/StyledBox";
import { CustomField } from "../controls/StyledField";
import StyledButton from "../controls/StyledButton";
import * as utils from "../../utils/commonUtils";
import JobItem from "./JobItem";
import * as metadata from "../../utils/metadata";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";
import { blueGrey } from "@mui/material/colors";
//import ReactToPrint from "react-to-print";

export const JobDetails = React.forwardRef((props, ref) => {
  const {
    values,
    setvalues,
    handleChange,
    resetForm,
    errors,
    handleError,
    newAlert,
    partList,
    custAssigned,
    handlePrint,
  } = props;

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  //const contentToPrint = useRef(null);
  const componentRef = useRef();

  //console.log("readonly flag in JObs: ", values.readOnly);
  //console.log('ref received in JobDetails: ', ref);

  let total_amount = 0;
  let total_gst = 0;
  let grand_total = 0;
  let disc = 0;

  const handleDiscount = (e) => {
    e.preventDefault();
    console.log("handle discount event: ", e);

    disc = e.target.value;
    console.log("Discount: ", disc);

    // update totals
    //reset totals
    total_amount = total_gst = grand_total = 0;

    for (let i = 0; i < values.jobItems.length; i++) {
      total_amount += parseInt(values.jobItems[i].part_total);
      total_gst += parseInt(values.jobItems[i].part_gst);
    }
    grand_total = total_amount + total_gst;

    grand_total = grand_total - disc;

    console.log("updated total: ", grand_total);

    setvalues((values) => ({
      ...values,
      total_amount: total_amount,
      total_gst: total_gst,
      grand_total: grand_total,
      discount: disc,
    }));
  };

  const submitJob = async (e) => {
    e.preventDefault();
    console.log("submit job with editMode: ", values.editMode);

    // first check if customer is assigned
    if (!values.editMode && !custAssigned) {
      newAlert("error", "Customer is not assigned!");
      return;
    }

    /*
    // check if job id is generated
    if (!values.job_id){
      newAlert("error", "Job ID is not generated!");
      return;
    }
    */

    //const date = utils.format(new Date());
    //const date = utils.getDBTimeStamp(new Date());
    const date = dayjs().format("YYYY-MM-DD HH:mm:ss");
    console.log(date);

    console.log("submit job state: ", values);

    /*
    // prepare job items
    console.log("jobItems: ", values.jobItems);
    let jobItemRequest = values.jobItems;
    for (let i = 0; i < values.jobItems.length; i++) {
      jobItemRequest[i].job_id = values.job_id;
      jobItemRequest[i].item_seq = i + 1;
    }

    console.log("job Items: ", jobItemRequest);
    //return;
    */

    // validate customer info
    if (values.mobile === "" || values.vehicle === "") {
      newAlert("error", "Please select customer and vehicle details!");
      return;
    }

    console.log(
      "service category: ",
      utils.getServiceCategory(values.service_type)
    );
    // create job
    try {
      const jobDetails = {
        date: date,
        customer_mobile: values.mobile,
        vehicle: values.vehicle,
        vehicle_km: values.vehicle_km,
        service_type: values.service_type,
        service_category: utils.getServiceCategory(values.service_type),
        advisor: values.advisor,
        technician: values.technician,
        cust_concern: values.customer_concern,
        diagnosis: values.diagnosis,
        status: metadata.JS_CHECKEDIN,
        ext_job_id: values.ext_job_id,
        amount: values.total_amount,
        gst: values.total_gst,
        total: values.grand_total,
        next_service: utils.getNextServiceType(values.service_type),
        next_service_type: utils.getNextServiceCategory(values.service_type),
        invoice_no: values.invoice_no || null,
        discount: values.discount || null,
        vehicle_color: values.vehicle_color,
        incentive: values.total_amount > 200 ? "YES" : "NO",
      };

      if (!values.editMode) {
        // new job scenario
        //await jobServices.createJob(jobDetails);
        const jobId = (
          await axiosPrivate.post(metadata.EP_CREATE_JOB, jobDetails)
        ).data;
        newAlert("success", "Job created successfully. Job Id: " + jobId.id);

        // prepare job items
        console.log("jobItems: ", values.jobItems);
        let jobItemRequest = values.jobItems;
        for (let i = 0; i < values.jobItems.length; i++) {
          jobItemRequest[i].job_id = jobId.id;
          jobItemRequest[i].item_seq = i + 1;
        }

        console.log("job Items: ", jobItemRequest);

        // create jobItems with new values
        //await jobServices.createJobItems(jobItemRequest);
        await axiosPrivate.post(metadata.EP_CREATE_JOB_ITEMS, jobItemRequest);

        //create job stages
        /*
        await jobServices.addJobStage({
          job_id: values.job_id,
          stage: "OVERALL",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        await jobServices.addJobStage({
          job_id: values.job_id,
          stage: "CHECKIN",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        */
        await axiosPrivate.post(metadata.EP_ADD_JOB_STAGE, {
          job_id: jobId.id,
          stage: "OVERALL",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        await axiosPrivate.post(metadata.EP_ADD_JOB_STAGE, {
          job_id: jobId.id,
          stage: "CHECKIN",
          starttime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        });
        newAlert("success", "Job Items created successfully!");

        // update stock
        for (var i = 0; i < jobItemRequest.length; i++) {
          console.log(
            "reducing stock of ",
            jobItemRequest[i].part_no,
            " by ",
            jobItemRequest[i].part_qty
          );
          /*
          await utils.updateStock(
            "REDUCE",
            jobItemRequest[i].part_no,
            jobItemRequest[i].part_qty
          );
          */
          await axiosPrivate.post(metadata.EP_REDUCE_STOCK, {
            part_no: jobItemRequest[i].part_no,
            count: jobItemRequest[i].part_qty,
          });
        }
        newAlert("success", "Stock Updated!");

        // set appt_no if exists
        if (values.appt_no) {
          console.log("setting job no with appointment");
          /*
          await apptServices.setAppointmentJobId({
            appt_no: values.appt_no,
            job_id: values.job_id,
          });
          */
          await axiosPrivate.post(metadata.EP_SET_APPOINTMENT_JOBID, {
            appt_no: values.appt_no,
            job_id: jobId.id,
          });

          // change appointment status to customer visit
          await axiosPrivate.post(metadata.EP_SET_APPOINTMENT_VISIT, {
            appt_no: values.appt_no,
          });
        } else {
          //attempt to find existing appointment
          console.log(
            "Checking if any appointment exists in the range ",
            utils.formatDate(dayjs().subtract(5,'day')),
            " and ",
            utils.formatDate(dayjs().add(10,'day'))
          );

          const apptFetched = (await axiosPrivate.post(
            metadata.EP_GET_JOB_APPT,
            {
              vehicle: values.vehicle,
              start_date: dayjs().subtract(5, "day").format('YYYY-MM-DD'),
              end_date: dayjs().add(10, "day").format('YYYY-MM-DD'),
            }
          )).data;
          console.log('Appt No found ==> ', apptFetched);

          if (apptFetched && apptFetched.id){
            console.log('setting job id to appointment');
            await axiosPrivate.post(metadata.EP_SET_APPOINTMENT_JOBID, {
              appt_no: apptFetched.id,
              job_id: jobId.id,
            });

            // change appointment status to customer visit
            await axiosPrivate.post(metadata.EP_SET_APPOINTMENT_VISIT, {
              appt_no: apptFetched.id,
            });
          } else{
            console.log('No Matching appointment found!')
          }
        }

        // set last visit date
        /*
        await customerServices.setCustomerLastVisit({
          cust_mobile: values.mobile,
          vehicle: values.vehicle,
          last_visit: utils.formatDate(dayjs()),
        });
        */
        await axiosPrivate.post(metadata.EP_SET_CUSTOMER_LAST_VISIT, {
          cust_mobile: values.mobile,
          vehicle: values.vehicle,
          last_visit: utils.formatDate(dayjs()),
        });
      } else {
        // update scenario

        const jobUpdateDetails = {
          id: values.job_id,
          date: date,
          customer_mobile: values.mobile,
          vehicle: values.vehicle,
          vehicle_km: values.vehicle_km,
          service_type: values.service_type,
          service_category: utils.getServiceCategory(values.service_type),
          advisor: values.advisor,
          technician: values.technician,
          cust_concern: values.customer_concern,
          diagnosis: values.diagnosis,
          status: metadata.JS_CHECKEDIN,
          ext_job_id: values.ext_job_id,
          amount: values.total_amount,
          gst: values.total_gst,
          total: values.grand_total,
          next_service: utils.getNextServiceType(values.service_type),
          next_service_type: utils.getNextServiceCategory(values.service_type),
          invoice_no: values.invoice_no || null,
          discount: values.discount || null,
          vehicle_color: values.vehicle_color,
          incentive: values.total_amount>200?'YES':'NO',
        };
        //await jobServices.updateJob(jobDetails);
        console.log("updateJob request ==>", jobUpdateDetails);
        await axiosPrivate.put(metadata.EP_UPDATE_JOB, jobUpdateDetails);
        newAlert("success", "Job updated. Job Id: " + values.job_id);

        // delete jobItems
        //await jobServices.deleteJobItems(values.job_id);
        await axiosPrivate.post(metadata.EP_DELETE_JOB_ITEMS, {
          job_id: values.job_id,
        });
        console.log("Job items deleted for job id: ", values.job_id);

        // adding job items

        // prepare job items
        console.log("jobItems: ", values.jobItems);
        let jobItemRequest = values.jobItems;
        for (let i = 0; i < values.jobItems.length; i++) {
          jobItemRequest[i].job_id = values.job_id;
          jobItemRequest[i].item_seq = i + 1;
        }

        console.log("job Items: ", jobItemRequest);
        //await jobServices.createJobItems(jobItemRequest);
        await axiosPrivate.post(metadata.EP_CREATE_JOB_ITEMS, jobItemRequest);
        newAlert("success", "Job Items updated successfully!");

        // update stock

        // add old items
        for (var i = 0; i < values.orig_jobItems.length; i++) {
          console.log(
            "restoring stock of ",
            values.orig_jobItems[i].part_no,
            " by ",
            -parseInt(values.orig_jobItems[i].part_qty)
          );
          /*
          await utils.updateStock(
            "REDUCE",
            values.orig_jobItems[i].part_no,
            -parseInt(values.orig_jobItems[i].part_qty)
          );
          */
          await axiosPrivate.post(metadata.EP_REDUCE_STOCK, {
            part_no: values.orig_jobItems[i].part_no,
            count: -parseInt(values.orig_jobItems[i].part_qty),
          });
        }
        console.log("stock restored");

        for (var i = 0; i < jobItemRequest.length; i++) {
          console.log(
            "reducing stock of ",
            jobItemRequest[i].part_no,
            " by ",
            jobItemRequest[i].part_qty
          );
          /*
          await utils.updateStock(
            "REDUCE",
            jobItemRequest[i].part_no,
            jobItemRequest[i].part_qty
          );
          */
          await axiosPrivate.post(metadata.EP_REDUCE_STOCK, {
            part_no: jobItemRequest[i].part_no,
            count: jobItemRequest[i].part_qty,
          });
        }
        newAlert("success", "Stock Updated!");
      }

      resetForm();

      //navigate to jobList
      navigate("/joblist");
    } catch (error) {
      handleError(error);
    }
  };

  const marginTop = "30px";
  const marginRight = "5px";
  const marginBottom = "10px";
  const marginLeft = "5px";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <form onSubmit={submitJob} id="job_details">
        <StyledBox border={1} id="job details">
          <Grid container>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography variant="h6" fontWeight={"bold"}>
                Job Details
              </Typography>
            </Grid>

            <Grid item xs={12} marginBottom={3}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650, backgroundColor: "white" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Typography variant="body" fontWeight={"bold"}>
                          Cust Id
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Typography variant="body" fontWeight={"bold"}>
                          NAME
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Typography variant="body" fontWeight={"bold"}>
                          VEHICLE
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Typography variant="body" fontWeight={"bold"}>
                          MOBILE
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{values.cust_id}</TableCell>
                      <TableCell align="center">{values.name}</TableCell>
                      <TableCell align="center">{values.vehicle}</TableCell>
                      <TableCell align="center">{values.mobile}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={4}>
              <CustomField
                value={values.job_id || ""}
                error={errors.job_id}
                onChange={handleChange}
                label="Job Id"
                name="job_id"
                required
                disabled
                //sx={{ width: 100}}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="service_type"
                name="service_type"
                value={values.service_type || ""}
                readOnly={values.readOnly}
                size="small"
                onChange={(event, newvalue) => {
                  //console.log("updating vehicle to: " + newvalue);
                  setvalues({ ...values, service_type: newvalue });
                }}
                options={metadata.SERVICE_TYPE}
                renderInput={(params) => (
                  <CustomField
                    {...params}
                    value={values.service_type || ""}
                    error={errors.service_type}
                    onChange={handleChange}
                    label="Service Type"
                    name="service_type"
                    readOnly={values.readOnly}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomField
                value={values.advisor || ""}
                error={errors.advisor}
                onChange={handleChange}
                label="Advisor"
                name="advisor"
                readOnly={values.readOnly}
                required
                select
              >
                {metadata.ADVISOR.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs={4}>
              <CustomField
                value={values.job_status || "CHECKED IN"}
                error={errors.job_status}
                onChange={handleChange}
                label="Job Status"
                name="job_status"
                readOnly
                required
                select
              >
                {utils.getJobStatusOptions(values.job_status).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs={4}>
              <CustomField
                value={values.technician || ""}
                error={errors.technician}
                onChange={handleChange}
                label="Technician"
                name="technician"
                readOnly={values.readOnly}
                required
                select
              >
                {metadata.TECHNICIAN.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Grid>
            <Grid item xs={4}>
              <CustomField
                value={values.vehicle_km || ""}
                error={errors.vehicle_km}
                onChange={handleChange}
                label="Vehicle KM"
                name="vehicle_km"
                readOnly={values.readOnly}
                required
              />
            </Grid>
            <Grid item xs={4} display={"flex"}>
              <CustomField
                value={values.customer_concern || ""}
                error={errors.customer_concern}
                onChange={handleChange}
                label="Customer Concerns"
                name="customer_concern"
                readOnly={values.readOnly}
                required
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={4} display={"flex"}>
              <CustomField
                value={values.diagnosis || ""}
                error={errors.diagnosis}
                onChange={handleChange}
                label="Diagnosis"
                name="diagnosis"
                readOnly={values.readOnly}
                required
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="vehicle_color"
                name="vehicle_color"
                value={values.vehicle_color || ""}
                readOnly
                size="small"
                onChange={(event, newvalue) => {
                  //console.log("updating vehicle to: " + newvalue);
                  setvalues({ ...values, vehicle_color: newvalue });
                }}
                options={metadata.VEHICLE_COLORS}
                renderInput={(params) => (
                  <CustomField
                    {...params}
                    value={values.vehicle_color || ""}
                    error={errors.vehicle_color}
                    onChange={handleChange}
                    label="Vehicle Color"
                    name="vehicle_color"
                    readOnly={values.readOnly}
                    //required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container>
            {values.jobItems.map((job_item, index) => (
              <JobItem
                key={index}
                values={values}
                setvalues={setvalues}
                newAlert={newAlert}
                job_item={job_item}
                index={index}
                partList={partList}
              />
            ))}

            <Grid item xs={2}>
              <CustomField
                label="Invoice #"
                name="invoice_no"
                type="number"
                readOnly
                value={values.invoice_no}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomField
                label="Payment Mode"
                name="payment_mode"
                value={values.payment_mode}
                readOnly
              />
            </Grid>
            <Grid item xs={2}>
              <CustomField
                label="Discount"
                name="discount"
                type="number"
                value={values.discount}
                onChange={handleDiscount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4} marginTop={2} marginRight={2}>
              <TableContainer>
                <Table
                  padding="normal"
                  size="small"
                  sx={{ padding: "5", margin: "2px" }}
                >
                  <TableBody>
                    <TableRow key="amount">
                      <TableCell width="100" align="center">
                        <Typography variant="inherit"> AMOUNT</Typography>
                      </TableCell>
                      <TableCell width="50" align="center">
                        ₹ {values.total_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow key="gst">
                      <TableCell width="100" align="center">
                        GST
                      </TableCell>
                      <TableCell width="50" align="center">
                        ₹ {values.total_gst}
                      </TableCell>
                    </TableRow>
                    <TableRow key="discount">
                      <TableCell width="100" align="center">
                        ADJUSTMENT
                      </TableCell>
                      <TableCell width="50" align="center">
                        ₹ {values.discount}
                      </TableCell>
                    </TableRow>
                    <TableRow key="total_amt">
                      <TableCell width="100" align="center">
                        TOTAL AMOUNT
                      </TableCell>
                      <TableCell width="50" align="center">
                        ₹ {values.grand_total}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {!values.readOnly && (
              <Grid item xs={10} justifyContent={"center"} display={"flex"}>
                <StyledButton
                  type="submit"
                  size="small"
                  //onClick={submitJob}
                  text={values.editMode ? "Update" : "Submit"}
                />
                <StyledButton
                  type="reset"
                  size="small"
                  onClick={resetForm}
                  color="inherit"
                  text="Reset"
                />
              </Grid>
            )}
          </Grid>
        </StyledBox>
      </form>
    </div>
  );
})
