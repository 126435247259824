import { getAPI, postAPI } from "./commonService";
import axios from "../api/axios";

export async function loginUser(props) {
  const URL = "/auth/v1/handleLogin";
  const body = props;
  try {
    const response = await axios.post(URL,body,{
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
    if (!error?.response) {
      throw new Error("No Server Response");
    } else if (error.response?.status === 400) {
      throw new Error("Missing Username or Password");
    } else if (error.response?.status === 401) {
      throw new Error("Unauthorized - Invalid username or password");
    } else {
      throw new Error(error.message);
    }
  }
}