import React, { useEffect } from 'react'
import { StyledBox } from '../controls/StyledBox'
import { Grid, MenuItem, Typography } from '@mui/material';
import { CustomField } from '../controls/StyledField';
import * as metadata from '../../utils/metadata';
import StyledButton from '../controls/StyledButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import * as purchaseServices from '../../services/purchaseService.js';
import dayjs from "dayjs";
//import * as accountServices from "../../services/accountService";
import useAxiosPrivate from '../../hooks/UseAxiosPrivate.js';
import useAuth from '../../hooks/UseAuth.js';

function PurchasePayment(props) {

  const {
    values,
    setvalues,
    resetForm,
    handleError,
    newAlert,
    handleChange,
    setOpenPurchasePayment,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  console.log('Auth in purchase payment 1: ', auth);
  console.log("Purchase with state: ", values);

  const submitJob = async(e) => {
    e.preventDefault();
    console.log('submit Payment with state: ', values);

    /*
    const paymentRequest = {
        payment_date: dayjs(values.payment_date).format('YYYY-MM-DD'),
        purchase_id: values.order_id,
        payment_amount: values.payment_amount,
        payment_source: values.payment_source,
        payment_mode: values.payment_mode,
        payment_paidby: values.payment_paidby,
    } 
    */
   const paymentRequest = {
    //date: dayjs(values.payment_date).format("YYYY-MM-DD"),
     date: dayjs().format("YYYY-MM-DD"),
     purchase_id: values.order_id,
     amount: values.payment_amount,
     payment_source: values.payment_source,
     payment_mode: values.payment_mode,
     paid_by: values.payment_paidby,
     vendor: values.vendor,
     expense_category: "PURCHASE",
     expense_type: "SPARES PURCHASE",
     description: "Payment for purchase id: " + values.order_id,
   };

    console.log('payment request: ', paymentRequest);

    try {
      // check if daily account is closed. If yes no expense allowed
      /*
      const dailyCollection = await accountServices.fetchDailyCollection(
        dayjs().format("YYYY-MM-DD")
      ); */ 
      const dailyCollection = (await axiosPrivate.post(
        metadata.EP_FETCH_DAILY_COLLECTION,
        { date: dayjs().format("YYYY-MM-DD") }
      )).data;
      const latestDate = dayjs(dailyCollection[0].latest_date).format(
        "YYYY-MM-DD"
      );
      if (latestDate === dayjs().format("YYYY-MM-DD")) {
        console.log("daily collection already completed");
        newAlert(
          "error",
          "Daily accounts already closed for the date! No expenses allowed"
        );
        return;
      }

      //await purchaseServices.addPurchasePayment(paymentRequest);
      //await purchaseServices.createExpense(paymentRequest);
      await axiosPrivate.post(metadata.EP_CREATE_EXPENSE,paymentRequest);

      // update company balance if company expense
      if (values.payment_source === metadata.PS_COMPANY) {
        console.log("Company Expense.. updating balances");
        /*
        await accountServices.updateBalances({
          bank_amount:
            values.payment_mode === "CASH"
              ? 0
              : parseInt(-values.payment_amount),
          cash_amount:
            values.payment_mode === "CASH"
              ? parseInt(-values.payment_amount)
              : 0,
        });
        */
        await axiosPrivate.post(metadata.EP_UPDATE_BALANCES, {
          bank_amount:
            values.payment_mode === "CASH"
              ? 0
              : parseInt(-values.payment_amount),
          cash_amount:
            values.payment_mode === "CASH"
              ? parseInt(-values.payment_amount)
              : 0,
        });
      }

      newAlert("success", "Payment added successfully!");
      resetForm();
      setOpenPurchasePayment(false);
    } catch (error) {
        handleError(error);
    }

  }

  useEffect(() => {
    
    const setSource = () => {

      console.log('user role => ', auth?.roles);
      console.log("set Source use effect!");

      if (auth && auth.roles.includes("account")) {
        // set source as company
        console.log("setting company as source");
        setvalues((values) => ({
          ...values,
          payment_source: "COMPANY",
        }));
      } else if (auth.roles.includes("admin")) {
        // admin so dont set anything
      } else {
        console.log("setting source as workshop");
        setvalues((values) => ({
          ...values,
          payment_source: "WORKSHOP",
        }));
      }
    }

    setSource();
  
   
  }, [values.payment_source])
  

  return (
    <form onSubmit={submitJob} id="payment">
      <StyledBox border={1}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={1}
          >
            <Typography variant="h6">Purchase Payment</Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Purchase ID"
              name="order_id"
              value={values.order_id}
              onChange={handleChange}
              disabled
              required
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "80%", margin: "8px" }}
                label="Payment Date"
                name="payment_date"
                required
                //readOnly={values.readOnly}
                //value={values.payment_date || null}
                value={dayjs()}
                onChange={(newvalue) =>
                  setvalues({
                    ...values,
                    payment_date: newvalue,
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Vendor"
              name="vendor"
              value={values.vendor}
              onChange={handleChange}
              disabled
              required
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={4}>
            <CustomField
              label="Payment Amount"
              name="payment_amount"
              value={values.payment_amount}
              type="number"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={4}>
            <CustomField
              label="Payment Source"
              name="payment_source"
              value={values.payment_source}
              //value="COMPANY"
              //readOnly
              select
              onChange={handleChange}
            >
              {metadata.PAYMENT_SOURCE.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Payment Mode"
              name="payment_mode"
              value={values.payment_mode}
              select
              onChange={handleChange}
            >
              {metadata.PAYMENT_MODE.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Paid by"
              name="payment_paidby"
              value={values.payment_paidby}
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={10} justifyContent={"center"} display={"flex"}>
            <StyledButton
              type="submit"
              size="small"
              //onClick={submitJob}
              text={values.editMode ? "Update" : "Submit"}
            />
            <StyledButton
              type="reset"
              size="small"
              onClick={resetForm}
              color="inherit"
              text="Reset"
            />
          </Grid>
        </Grid>
      </StyledBox>
    </form>
  );
}

export default PurchasePayment