import React from 'react'
import MonthlyReport from '../components/reports/MonthlyReport'
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import * as utils from "../utils/commonUtils";
import { useLoading, Loading } from "../hooks/UseLoading";
import useAuth from "../hooks/UseAuth";
import { useForm } from "../hooks/UseForm";
import { StyledBox } from '../components/controls/StyledBox';
import { Tab, Tabs } from '@mui/material';
import MonthlySummary from '../components/reports/MonthlySummary';

const MgmtReports = () => {
    const axiosPrivate = useAxiosPrivate();
    const { startLoading, stopLoading, isloading } = useLoading();
    const { auth } = useAuth();
    const {
      values,
      handleError,
    } = useForm("");
    const [monthlyData, setMonthlyData] = useState([]) 
    const [tabIndex, settabIndex] = useState(0);

    useEffect(() => {
        const fetchMonthlyData = async () => {
            try {
              startLoading();

              let monthReport = (await axiosPrivate.post(metadata.EP_FETCH_MONTHLY_REPORT)).data;

              console.log('monthly data: ', monthReport);
              

              //Fix old reporting data till Apr-2024
              monthReport = utils.fixMonthlyReport(monthReport);

              setMonthlyData(monthReport);
              stopLoading();

            } catch (error) {
              handleError(error);
            }
            
        } 
        fetchMonthlyData().catch((error) => handleError(error));
    },[])

    const handleTabChange = (event, newValue) => {
      settabIndex(newValue);
    };

  return (
    <>
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="P/L Summary" id="tab-0" />
          <Tab label="P/L Detailed" id="tab-1" />
        </Tabs>
      </StyledBox>

      {tabIndex === 0 && (
        <StyledBox border={0}>
          <MonthlySummary data={monthlyData} />
        </StyledBox>
      )}

      {tabIndex === 1 && (
        <StyledBox border={0}>
          <MonthlyReport data={monthlyData} />
        </StyledBox>
      )}
      
    </>
  ); 
}

export default MgmtReports