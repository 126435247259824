import React from "react";
import PageHeader from "../components/layout/PageHeader";
import CallIcon from "@mui/icons-material/Call";
import CustomerCalling from "../components/appt/CustomerCallingForm";

function CustomerCallingDB() {
  return (
    <>
      <PageHeader
        title="Customer Call Dashboard"
        subTitle="Customer appointments and reminder calls"
        icon={<CallIcon fontSize="medium" />}
      />
      <CustomerCalling />
    </>
  );
}

export default CustomerCallingDB;
