import React, { useState } from "react";
import { StyledBox } from "../controls/StyledBox";
import {
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

function CustomerHistory(props) {
  const { callHistory, visitHistory } = props;
  const [tabIndex, settabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    settabIndex(newValue);
  };

  return (
    <>
      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Visit History" id="tab-0" />
          <Tab label="Call History" id="tab-1" />
        </Tabs>
      </StyledBox>
      <StyledBox border={0}>
        <Grid container>
          {tabIndex === 0 && (
            <Grid item xs={12} padding={1}>
              {visitHistory.length > 0 ? (
                <TableContainer  container={Paper}>
                  <Table sx={{ width: "max-content" }} size="small">
                    <TableHead>
                      <TableRow hover>
                        <TableCell>DATE</TableCell>
                        <TableCell>JOB ID</TableCell>
                        <TableCell>MOBILE</TableCell>
                        <TableCell>VEHICLE</TableCell>
                        <TableCell>SERVICE TYPE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visitHistory.map((visit) => (
                        <TableRow key={visit.job_id} hover>
                          <TableCell>
                            {visit.date &&
                              dayjs(visit.date).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell>{visit.job_id}</TableCell>
                          <TableCell>{visit.customer_mobile}</TableCell>
                          <TableCell>{visit.vehicle}</TableCell>
                          <TableCell>{visit.service_type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No Visit Data</Typography>
              )}
            </Grid>
          )}

          {tabIndex === 1 && (
            <Grid item xs={12} padding={1}>
              {callHistory.length > 0 ? (
                <TableContainer container={Paper}>
                  <Table sx={{ width: "max-content" }} size="small">
                    <TableHead>
                      <TableRow hover>
                        <TableCell>CALL DATE</TableCell>
                        <TableCell>CALL TYPE</TableCell>
                        <TableCell>OUTCOME</TableCell>
                        <TableCell>APPT_DATE</TableCell>
                        <TableCell>CALL NOTES</TableCell>
                        <TableCell>CRE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {callHistory &&
                        callHistory.map((call, i) => (
                          <TableRow key={i} hover>
                            <TableCell>
                              {call.date &&
                                dayjs(call.date).format("DD-MMM-YYYY")}
                            </TableCell>
                            <TableCell>{call.call_type}</TableCell>
                            <TableCell>{call.outcome}</TableCell>
                            <TableCell>
                              {call.appt_date &&
                                dayjs(call.appt_date).format("DD-MMM-YYYY")}
                            </TableCell>
                            <TableCell>{call.notes}</TableCell>
                            <TableCell>{call.cre}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No Calling Data</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </StyledBox>
    </>
  );
}

export default CustomerHistory;
