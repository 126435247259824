import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Card, Paper, Typography } from "@mui/material";
import useAuth from "../../hooks/UseAuth";

const RootStyle = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fdfdff",
}));

const HeaderStyle = styled(Box)(({ theme }) => ({
  marginBottom:theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex'
}));

const IconStyle = styled(Card)(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(2),
  color: "#3c44b1",
}));

const TitleStyle = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  "& .MuiTypography-subtitle2": {
    opacity: "0.6",
  },
}));

function PageHeader(props) {
  const { title, subTitle, icon } = props;
  const {auth } = useAuth();
  //console.log('Auth in page Header ==> ', auth);
  return (
    <RootStyle elevation={1} square>
      <HeaderStyle>
        <IconStyle>{icon}</IconStyle>
        <TitleStyle>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subTitle}
          </Typography>
        </TitleStyle>
      </HeaderStyle>
    </RootStyle>
  );
}

export default PageHeader;
