import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledBox } from "../controls/StyledBox";
import dayjs from "dayjs";

export default function DailyReportMobileSummary(props) {
  const { jobs, expenses, totals, reportDate, jobCategories } = props;

  return (
    <StyledBox border={0}>
      <Grid container>
        <Grid item xs={12} textAlign={"center"} padding={1}>
          <Typography variant="h5">Collection Report Report</Typography>
          <Typography variant="h6">
            {dayjs(reportDate).format("DD-MMM-YYYY")}
          </Typography>
          <br />
          <Typography variant="body">Total Vehicles: {jobs.length}</Typography>
          <br />
          <Typography variant="body">
            Free:{" "}
            {
              jobs.filter(function (j) {
                return j.service_category === "FREE SERVICE";
              }).length
            }{" "}
            Paid:{" "}
            {
              jobs.filter(function (j) {
                return j.service_category === "PAID SERVICE";
              }).length
            }
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={"center"} padding={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell>
                    <Typography variant="h6">Total Collection</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      ₹ {totals.collection.toLocaleString("en-IN")}
                    </Typography>
                  </TableCell>
                </TableRow>
                {jobCategories.map((jobcat) => (
                  <TableRow hover>
                    <TableCell
                      sx={{
                        //borderBottom: "none",
                        paddingTop: 0.8,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography variant="body">
                        {jobcat.part_category}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        
                        paddingTop: 0.8,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography variant="body">₹ {jobcat.sum}</Typography>
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow hover>
                  <TableCell>
                    <Typography variant="h6">Total Expense</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      ₹ {totals.expense.toLocaleString("en-IN")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>
                    <Typography variant="h6">Nett Collection</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      ₹{" "}
                      {(totals.collection - totals.expense).toLocaleString(
                        "en-IN"
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {jobs.length === 0 && <Typography>No Data found</Typography>}
      </Grid>
    </StyledBox>
  );
}
