import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefeshToken from "../hooks/UseRefesh";
import useAuth from "../hooks/UseAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefeshToken();
    const { auth, persist } = useAuth();

    useEffect( () => {

        let isMounted = true;

        const verifyRefreshToken = async () => {
            try{
                await refresh();
            } catch (err){
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken(): setIsLoading(false);

        return () => isMounted = false;

    },[])
    /*
    useEffect(() => {
        console.log('isLoading: ', isLoading);
        console.log('Auth token: ', auth?.accessToken);
    },[isLoading])
    */

    return (
        <>
        {!persist
            ? <Outlet />
                :isLoading
                    ? <p>Loading ...</p>
                    : <Outlet />
        }
        </>
    )
}

export default PersistLogin