import React from "react";
import { StyledBox } from "../controls/StyledBox";
import {
  Grid,
  Typography,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Fab,
} from "@mui/material";
import { CustomField } from "../controls/StyledField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PurchaseItem from "./PurchaseItem";
import StyledButton from "../controls/StyledButton";
import { useEffect, useState } from "react";
//import * as purchaseServices from "../../services/purchaseService";
//import * as partServices from "../../services/partService";
//import * as utils from "../../utils/commonUtils";
import EditIcon from "@mui/icons-material/Edit";
import * as metadata from "../../utils/metadata";
import useAxiosPrivate from "../../hooks/UseAxiosPrivate";

function PurchaseForm(props) {
  const {
    values,
    setvalues,
    handleChange,
    resetForm,
    handleError,
    newAlert,
    openPurchase,
    setOpenPurchase,
  } = props;

  const deliveryDateRcvd = values.orig_delivery;
  console.log("delivery date received: ", deliveryDateRcvd);

  const [vendors, setvendors] = useState([{}]);
  const [parts, setParts] = useState([{}]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    const fetchVendors = async () => {
      //const vendors = await purchaseServices.fetchVendors();
      const vendors = (await axiosPrivate.get(metadata.EP_FETCH_VENDORS)).data;
      console.log("vendors: ", vendors);
      setvendors(vendors);

      //const partList = await partServices.fetchParts();
      const partList = (await axiosPrivate.get(metadata.EP_FETCH_PARTS)).data;
      setParts(partList);
      console.log("Part List set!", partList);
    };
    fetchVendors().catch((error) => handleError(error));
  }, []);

  console.log("state received in purchaseForm: ", values);

  let total_amount = 0;
  let total_gst = 0;
  let grand_total = 0;
  let disc = 0;

  const handleDiscount = (e) => {
    e.preventDefault();
    console.log("handle discount event: ", e);

    disc = e.target.value;
    console.log("Discount: ", disc);

    // update totals
    //reset totals
    total_amount = total_gst = grand_total = 0;

    for (let i = 0; i < values.purchaseItems.length; i++) {
      total_amount += parseInt(values.purchaseItems[i].part_total);
      total_gst += parseInt(values.purchaseItems[i].part_gst);
    }
    grand_total = total_amount + total_gst;

    grand_total = grand_total - disc;

    console.log("updated total: ", grand_total);

    setvalues((values) => ({
      ...values,
      total_amount: total_amount,
      total_gst: total_gst,
      grand_total: grand_total,
      discount: disc,
    }));
  };

  const submitJob = async (e) => {
    e.preventDefault();
    console.log("submit purchase with editMode: ", values.editMode);
    console.log("current state: ", values);
    console.log("dialog state: ", openPurchase);

    console.log("order date: ", dayjs(values.order_date).format("YYYY-MM-DD"));

    console.log(
      values.purchaseItems.length,
      " ",
      values.purchaseItems[0].part_total
    );
    if (!values.order_date) {
      newAlert("error", "Vendor and Order Date must be Set!");
      return;
    }

    if (
      values.purchaseItems.length === 1 &&
      !values.purchaseItems[0].part_total
    ) {
      newAlert("error", "Purchase Items missing!");
      return;
    }

    try {
      const purchaseRequest = {
        purchase_id: values.order_id,
        order_date: dayjs(values.order_date).format("YYYY-MM-DD"),
        delivery_date: values.delivery_date
          ? dayjs(values.delivery_date).format("YYYY-MM-DD")
          : null,
        vendor: values.vendor,
        amount: values.total_amount,
        gst: values.total_gst,
        total: values.grand_total,
        discount: values.discount && parseInt(values.discount),
        final_amount: values.grand_total,
      };

      console.log("Purchase request: ", purchaseRequest);

      if (!values.editMode) {
        // new purchase scenario
        /*
        const purchaseId = await purchaseServices.createPurchase(
          purchaseRequest
        );
        */
        const purchaseId = (
          await axiosPrivate.post(metadata.EP_CREATE_PURCHASE, purchaseRequest)
        ).data;
        console.log("Purchase created id: ", purchaseId.id);

        // create purchase Items
        let purchaseItemRequest = [];
        values.purchaseItems.map((item, index) => {
          purchaseItemRequest.push({
            purchase_id: purchaseId.id,
            item_seq: index + 1,
            part_no: item.part_no,
            part_cost: parseInt(item.part_cost),
            quantity: parseInt(item.part_qty),
            amount: item.part_amt,
            gst: item.part_gst,
            total: item.part_total,
          });
        });
        console.log("Purchase item request: ", purchaseItemRequest);
        //await purchaseServices.createPurchaseItems(purchaseItemRequest);
        await axiosPrivate.post(
          metadata.EP_CREATE_PURCHASE_ITEMS,
          purchaseItemRequest
        );

        // update stock
        if (purchaseRequest.delivery_date !== null) {
          console.log("updating stock!");
          purchaseItemRequest.map(async (item, index) => {
            console.log("adding ", item.quantity, " to part: ", item.part_no);
            //await utils.updateStock("ADD", item.part_no, item.quantity);
            await axiosPrivate.post(metadata.EP_ADD_STOCK, {
              part_no: item.part_no,
              count: item.quantity,
            });
          });
        } else {
          console.log(
            "skipping stock update, delivery_date is : ",
            purchaseRequest.delivery_date
          );
        }

        newAlert(
          "success",
          "Purchase Order Created successfully. Purchase ID: " + purchaseId.id
        );
      } else {
        // update scenario
        //await purchaseServices.updatePurchase(purchaseRequest);
        await axiosPrivate.put(metadata.EP_UPDATE_PURCHASE,purchaseRequest);
        console.log("Purchase updated successfully");

        /*
        await purchaseServices.deletePurchaseItems({
          purchase_id: values.order_id,
        });
        */
       await axiosPrivate.post(metadata.EP_DELETE_PURCHASE_ITEMS, {
         purchase_id: values.order_id,
       });
        console.log("Purchase items deleted with order id: ", values.order_id);

        // create purchase Items
        let purchaseItemRequest = [];
        values.purchaseItems.map((item, index) => {
          purchaseItemRequest.push({
            purchase_id: values.order_id,
            item_seq: index + 1,
            part_no: item.part_no,
            part_cost: parseInt(item.part_cost),
            quantity: parseInt(item.part_qty),
            amount: item.part_amt,
            gst: item.part_gst,
            total: item.part_total,
          });
        });
        console.log("Purchase items update request: ", purchaseItemRequest);

        //await purchaseServices.createPurchaseItems(purchaseItemRequest);
        await axiosPrivate.post(metadata.EP_CREATE_PURCHASE_ITEMS,purchaseItemRequest);
        newAlert("success", "Purchase updated successfully!");

        // update stock if applicable
        if (purchaseRequest.delivery_date !== null && !deliveryDateRcvd) {
          console.log("updating stock!");
          purchaseItemRequest.map(async (item, index) => {
            console.log("adding ", item.quantity, " to part: ", item.part_no);
            //await utils.updateStock("ADD", item.part_no, item.quantity);
            await axiosPrivate.post(metadata.EP_ADD_STOCK,{part_no: item.part_no, count: item.quantity});
          });
        } else {
          console.log(
            "skippping stock update. deliveryDateRcvd: ",
            deliveryDateRcvd,
            " delivery date set: ",
            purchaseRequest.delivery_date
          );
        }
      }

      // reset form and exit
      resetForm();
      //setOpenPurchase(false);
      setOpenPurchase(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleEdit = () => {
    // allow edit

    if (deliveryDateRcvd) {
      newAlert("error", "Edit not allowed on Delivered Purchases!");
      return;
    }
    setvalues((values) => ({
      ...values,
      readOnly: false,
      editMode: true,
    }));
  };

  return (
    <form onSubmit={submitJob} id="purchase">
      <StyledBox border={1}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={1}
          >
            <Typography variant="h6">Purchase Details</Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomField
              label="Vendor"
              name="vendor"
              required
              readOnly={values.readOnly}
              value={values.vendor}
              onChange={handleChange}
              select
            >
              {vendors.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </CustomField>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={4}>
              <DatePicker
                sx={{ width: "80%", margin: "8px" }}
                label="Order Date"
                name="order_date"
                required
                readOnly={values.readOnly}
                value={values.order_date || null}
                onChange={(newvalue) =>
                  setvalues({
                    ...values,
                    order_date: newvalue,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                sx={{ width: "80%", margin: "8px" }}
                label="Delivery Date"
                name="delivery_date"
                readOnly={values.readOnly}
                value={values.delivery_date || null}
                onChange={(newvalue) =>
                  setvalues({
                    ...values,
                    delivery_date: newvalue,
                  })
                }
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid container>
          {values.purchaseItems.map((purchase_item, index) => (
            <PurchaseItem
              key={index}
              values={values}
              setvalues={setvalues}
              newAlert={newAlert}
              purchase_item={purchase_item}
              index={index}
              parts={parts}
            />
          ))}

          <Grid item xs={5} />
          <Grid item xs={2}>
            <CustomField
              label="Discount"
              name="discount"
              type="number"
              disabled={Boolean(values.readOnly)}
              value={values.discount}
              onChange={handleDiscount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} marginTop={2} marginRight={2}>
            <TableContainer>
              <Table
                padding="normal"
                size="small"
                sx={{ padding: "5", margin: "2px" }}
              >
                <TableBody>
                  <TableRow key="amount">
                    <TableCell width="100" align="center">
                      <Typography variant="inherit"> AMOUNT</Typography>
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.total_amount}
                    </TableCell>
                  </TableRow>
                  <TableRow key="gst">
                    <TableCell width="100" align="center">
                      GST
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.total_gst}
                    </TableCell>
                  </TableRow>
                  <TableRow key="discount">
                    <TableCell width="100" align="center">
                      ADJUSTMENT
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.discount}
                    </TableCell>
                  </TableRow>
                  <TableRow key="total_amt">
                    <TableCell width="100" align="center">
                      TOTAL AMOUNT
                    </TableCell>
                    <TableCell width="50" align="center">
                      ₹ {values.grand_total}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {!values.readOnly && (
            <Grid item xs={10} justifyContent={"center"} display={"flex"}>
              <StyledButton
                type="submit"
                size="small"
                //onClick={submitJob}
                text={values.editMode ? "Update" : "Submit"}
              />
              <StyledButton
                type="reset"
                size="small"
                onClick={resetForm}
                color="inherit"
                text="Reset"
              />
            </Grid>
          )}
        </Grid>
      </StyledBox>
      {values.readOnly && (
        <Fab
          color="secondary"
          sx={{ position: "fixed", bottom: 20, right: 20 }}
          onClick={handleEdit}
        >
          <EditIcon></EditIcon>
        </Fab>
      )}
    </form>
  );
}

export default PurchaseForm;
