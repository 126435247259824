import React,{ useState } from  "react";
import PageHeader from "../components/layout/PageHeader";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import {DailyCollectionExpense} from "../components/reports/DailyCollectionExpense";
import dayjs from "dayjs";
import { useForm } from "../hooks/UseForm";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import { StyledBox } from "../components/controls/StyledBox";
import { Grid, IconButton, Tab, Tabs } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StyledButton from "../components/controls/StyledButton";
import DailyReportMobile from "../components/reports/DailyReportMobile";
import DailyReportMobileSummary from "../components/reports/DailyReportMobileSummary";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

function DailyJobList() {
    var duration = require("dayjs/plugin/duration");
    dayjs.extend(duration);
    const [reportDate, setreportDate] = useState(null);
    const [jobs, setJobs] = useState([{}]);
    const [expenses, setExpenses] = useState([{}]);
    const { newAlert, handleError } = useForm("");
    const [totals, setTotals] = useState({ collection: 0, expense: 0 });
    const axiosPrivate = useAxiosPrivate();
    const [tabIndex, settabIndex] = useState(0);
    const [jobCategories, setjobCategories] = useState([{}]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      documentTitle: "Star Daily Report",
      content: () => componentRef.current,
    });

    const fetchDetails = async (e) => {
      e.preventDefault();
      if (reportDate === null) {
        newAlert("error", "Please select the date");
        return;
      }
      console.log(
        "fetching report data for date",
        dayjs(reportDate).format("YYYY-MM-DD")
      );

      try {

        const expenseList = (
          await axiosPrivate.post(metadata.EP_FETCH_EXPENSES_BYDATE, {
            expense_date: dayjs(reportDate).format("YYYY-MM-DD"),
            payment_source: "WORKSHOP",
          })
        ).data;

        var totalExpense = 0;
        for (var i = 0; i < expenseList.length; i++) {
          console.log("adding: ", expenseList[i].amount);
          totalExpense += expenseList[i].amount;
        }

        console.log("Expenses list: ", expenseList);
        console.log("Total: ", totalExpense);

        setExpenses(expenseList);
        setTotals((values) => ({
          ...values,
          expense: totalExpense,
        }));

        const jobList = (
          await axiosPrivate.post(metadata.EP_GET_JOBLIST_BYDATE, {
            job_date: dayjs(reportDate).format("YYYY-MM-DD"),
            job_stage: "OVERALL",
            job_status: metadata.JS_BILLING_COMPLETE,
          })
        ).data;

        var totalCollection = 0;
        var startTime;
        var endTime;
        var diff;
        for (var i = 0; i < jobList.length; i++) {
          console.log("adding: ", jobList[i].total);
          totalCollection += jobList[i].total;
          //calculate duration
          startTime = dayjs(jobList[i].starttime);
          endTime = dayjs(jobList[i].endtime);

          diff = dayjs.duration(endTime.diff(startTime));
          console.log("Duration ==> ", diff.format("HH:mm"));

          jobList[i] = { ...jobList[i], duration: diff.format("HH:mm") };
        }

        console.log("Job list: ", jobList);
        console.log("Collection total: ", totalCollection);

        setJobs(jobList);
        setTotals((values) => ({
          ...values,
          collection: totalCollection,
        }));

        //fetching job totals by category
        const jobCategoryTotals = (
          await axiosPrivate.post(metadata.EP_GET_JOBLIST_DETAILS_BYDATE, {
            job_date: dayjs(reportDate).format("YYYY-MM-DD"),
            job_stage: "OVERALL",
            job_status: metadata.JS_BILLING_COMPLETE,
          })
        ).data;

        console.log('Job category totals => ', jobCategoryTotals);
        setjobCategories(jobCategoryTotals);

      } catch (error) {
        handleError(error);
      }
    };

    const handleTabChange = (event, newValue) => {
    settabIndex(newValue);
  };

  return (
    <>
      <PageHeader
        title="Daily Workshop Collection & Expense Report"
        subTitle="Date wise collection and Expenses"
        icon={<ListAltTwoToneIcon fontSize="medium" />}
      />
      <form onSubmit={fetchDetails} id="dailyreport">
        <StyledBox border={0}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", margin: "8px" }}
                  label="Select Date"
                  name="report_date"
                  //value={reportDate || null}
                  required
                  onChange={(newvalue) => setreportDate(newvalue)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={8}>
              <StyledButton type="submit" size="large" text="Submit" />
            </Grid>
          </Grid>
        </StyledBox>
      </form>

      <StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container>
          <Grid item xs={10}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Desktop" id="tab-1" />
              <Tab label="Mobile" id="tab-2" />
              <Tab label="Summary" id="tab-3" />
            </Tabs>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handlePrint}>
              <PrintIcon color="primary" size="large" />
            </IconButton>
          </Grid>
        </Grid>
      </StyledBox>

      {tabIndex === 0 && (
        <DailyCollectionExpense
          jobs={jobs}
          expenses={expenses}
          totals={totals}
          reportDate={reportDate}
          ref={componentRef}
        />
      )}

      {tabIndex === 1 && (
        <DailyReportMobile jobs={jobs} expenses={expenses} totals={totals} />
      )}

      {tabIndex === 2 && (
        <DailyReportMobileSummary
          jobs={jobs}
          expenses={expenses}
          totals={totals}
          reportDate={reportDate}
          jobCategories={jobCategories}
        />
      )}
    </>
  );
}

export default DailyJobList;
