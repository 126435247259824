import React, { useEffect, useState } from 'react'
import { useForm } from '../hooks/UseForm';
//import * as apptServices from "../services/apptService";
import { Container, Dialog, DialogContent, Fab, Grid, TextField } from '@mui/material';
import ApptCard from '../components/appt/ApptCard';
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import * as metadata from "../utils/metadata";
import * as utils from "../utils/commonUtils";
import CustomerCallingForm from '../components/appt/CustomerCallingForm';
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import { green, grey } from '@mui/material/colors';
import { useLoading, Loading } from "../hooks/UseLoading";

function AppointmentList() {

  const { newAlert, handleError } = useForm("");
  const [appt, setappt] = useState([]);
  const [reloadAppt, setreloadAppt] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [call, setcall] = useState({});
  const [callDB, setcallDB] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { startLoading, stopLoading, isloading } = useLoading();

  useEffect(() => {
    // load the appointments;
    const fetchAppointments = async () => {
      
      startLoading();
      //const apptList = await apptServices.getAppointmentList();
      const apptList = (await axiosPrivate.get(metadata.EP_GETAPPOINTMENT_LIST)).data;
      
      //console.log('apptList original: ', apptList);
      var updatedList = [];
      // prepare list
      for (var i=0;i<apptList.length;i++){
        updatedList.push({
          ...apptList[i],
         status: apptList[i].status?apptList[i].status:'SCHEDULED',
         service_category: utils.getServiceCategory(apptList[i].service_type)
        })
      }
      console.log("apptList with change: ", updatedList);
      setappt(updatedList);
      stopLoading();
    };
    //console.log("fetching Appt!!");
    fetchAppointments().catch((error) => handleError(error));
  }, [reloadAppt]);

  const handleNewCall = () => {
    //console.log("create new call!");
    navigate("/callingDashboard");
  };

  const keys = [
    "cre",
    "mobile",
    "name",
    "vehicle",
    "service_type",
    "date",
    "status"
  ];

  const filteredAppts = (apptList) => {

    //console.log('filtering: ', apptList);

    if (!query){
      return appt;
    }

    return apptList.filter((appointment) =>
      keys.some((key) => appointment[key].includes(query))
    );

  }

  const handleCloseDB = () => {
    console.log("handling calling DB close action");

    //refresh calling list
    setreloadAppt((prev) => !prev);
    console.log("list refreshed!!");

    // close calling DB
    setcallDB(false);
  }

  return (
    <>
      <Container sx={{ margin: 2 }}>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-search"
          label="Search Appointments"
          type="search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Container>
      <Container sx={{ marginTop: 3 }}>
        <Grid container spacing={6}>
          {filteredAppts(appt).map((appt) => (
            <Grid key={appt.id} item xs={12} sm={6} md={4} lg={3}>
              <ApptCard
                appt={appt}
                handleError={handleError}
                key={appt.id}
                newAlert={newAlert}
                setreloadAppt={setreloadAppt}
                setcallDB={setcallDB}
                setcall={setcall}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        onClick={handleNewCall}
      >
        <AddIcon />
      </Fab>
      <Dialog open={callDB} onClose={handleCloseDB} maxWidth>
        <DialogContent>
          <CustomerCallingForm call={call} handleCloseDB={handleCloseDB} />
        </DialogContent>
      </Dialog>
      <Loading isloading={isloading} />
    </>
  );
}

export default AppointmentList