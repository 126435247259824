import React from 'react'
import  { TextField } from '@mui/material'
import { styled } from "@mui/material/styles";


const SyledField = styled(TextField)(({ theme }) => ({
  width: "80%",
  margin: theme.spacing(1),
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
})); 

const SyledField2 = styled(TextField)(({ theme }) => ({
  width: "95%",
  margin: theme.spacing(1),
  '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
})); 


export function CustomField(props) {
  
  var { name, label, value, error=null, onChange, readOnly, ...others } = props;

  if (!readOnly || readOnly === '' || readOnly===null) {
     readOnly = false;
  }

  return (
    <SyledField
      variant="filled"
      size="small"
      autoComplete="off"
      label={label}
      name={name}
      onChange={onChange}
      value={value}
      InputProps={{
        readOnly: readOnly,
      }}
      {...(error && { error: true, helperText: error })}
      {...others}
    />
  );
}

export function CustomField2(props) {
   var {
     name,
     label,
     value,
     error = null,
     onChange,
     readOnly,
     ...others
   } = props;

   if (!readOnly || readOnly === "" || readOnly === null) {
     readOnly = false;
   }

  return (
    <SyledField2
      variant="outlined"
      size="small"
      label={label}
      autoComplete="off"
      name={name}
      onChange={onChange}
      value={value || ""}
      InputProps={{
        readOnly: readOnly,
      }}
      {...(error && { error: true, helperText: error })}
      {...others}
    />
  );
}
