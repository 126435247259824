import React from 'react';
import { StyledBox } from "../controls/StyledBox";
import { DataGrid } from '@mui/x-data-grid';
import * as utils from '../../utils/commonUtils';
import { styled } from "@mui/material/styles";

export default function MonthlySummary(props) {
  const { data } = props;
  console.log("data: ", data);

  // Define styled DataGrid
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .collection-column": {
      backgroundColor: "#f0f8ff", // Light blue
    },
    "& .total-exp-column": {
      backgroundColor: "#faebd7", // Antique white
    },
    "& .pl-column": {
      backgroundColor: "#e6e6fa", // Lavender
    },
    "& .booking-column": {
      backgroundColor: "#C7F6C7", // Green
    },
  }));

  const columns = [
    {
      field: "month",
      headerName: "MONTH",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_jobs",
      headerName: "JOBS",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "free_service_jobs",
      headerName: "FREE",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paid_service_jobs",
      headerName: "PAID",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "amc_service_jobs",
      headerName: "AMC",
      width: 75,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const row = params.row;
        /*console.log("ROW => ", row);*/
        if (!row || !row.amc_service_jobs || !row.amc_jobs) {
          return null;
        }
        return Number(row.amc_service_jobs) + Number(row.amc_jobs);
      },
    },
    {
      field: "collection",
      headerName: "COLLECTION",
      width: 110,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => utils.formatAsIndianCurrency(params.value),
      cellClassName: "collectionColumn",
    },
    {
      field: "total_exp",
      headerName: "EXPENSES",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => utils.formatAsIndianCurrency(params.value),
    },
    {
      field: "partner_exp",
      headerName: "BOOKINGS",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => utils.formatAsIndianCurrency(params.value),
    },
    {
      field: "p/l",
      headerName: "P/L",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const row = params.row;
        /*console.log("ROW => ", row);*/
        if (!row || !row.collection || !row.total_exp) {
          return null;
        }
        return row.collection - row.total_exp;
      },
      valueFormatter: (params) => utils.formatAsIndianCurrency(params.value),
    },
  ];
  return (
    <>
      <StyledBox border={0}>
        <StyledDataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.month}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 15 } }
          }}
          pageSizeOptions={[15, 20, 25]}
          getCellClassName={(params) => {
            if (params.field === "collection") return "collection-column";
            if (params.field === "total_exp") return "total-exp-column";
            if (params.field === "p/l") return "pl-column";
            if (params.field === "partner_exp") return "booking-column";
            return "";
          }}
        />
      </StyledBox>
    </>
  );
}
