import { Link } from "react-router-dom";
import { StyledBox } from "../components/controls/StyledBox";

const Missing = () => {
  return (
    <StyledBox border={1}>
      <article style={{ padding: "100px" }}>
        <h1>Oops!</h1>
        <p>Page Not Found</p>
        <div className="flexGrow">
          <Link to="/">Visit Our Homepage</Link>
        </div>
      </article>
    </StyledBox>
  );
};

export default Missing;
