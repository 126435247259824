import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const CustomBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
}));

export function StyledBox(props) {
  const { children, ...other } = props;
  return ( 
  <CustomBox {...other}>
    {children}
</CustomBox>
  );
}
