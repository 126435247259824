import axios from "../api/axios";
import useAuth from "./UseAuth";


const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try{
            const response = await axios("/auth/v1/logout", {
              withCredentials: true,
            });
            console.log('Logout Response: ', response);
        } catch (error){
            console.error(error);
        }
    }
    return logout;
}

export default useLogout