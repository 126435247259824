import React from "react";
import { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export function useLoading() {
  const [isloading, setisLoading] = useState(false);

  const startLoading = () => {
    setisLoading(true);
  };

  const stopLoading = () => {
    setisLoading(false);
  };

  return {
    isloading,
    startLoading,
    stopLoading,
  };
}


// functional component of loading
export function Loading(prop) {
  const { isloading } = prop;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isloading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}