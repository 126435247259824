import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const Location = () => {
  return (
    <>
      <Container id="location" sx={{ py: { xs: 8, sm: 4 } }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography textAlign="center" variant="h5" color={"inherit"}>
              Suzuki Center
            </Typography>
            <div className="google-map-code">
              <iframe
                title="star_suzuki1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.22962182972!2d72.82618257572297!3d18.965463855432265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce3ff7b85721%3A0xcce7fb640bc8517f!2sSuzuki%20Authorized%20Service%20Center%20(Star%20Automobiles)!5e0!3m2!1sen!2sus!4v1708378302123!5m2!1sen!2sus"
                width="500"
                height="350"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign="center" variant="h5" color={"inherit"}>
              TVS Center
            </Typography>
            <div className="google-map-code">
              <iframe
                title="startvs"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13003.065591437407!2d72.82287315919477!3d18.967803531413686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf251721eeb7%3A0x93c043d35433f9fc!2sStar%20TVS%20(Authorized%20Service%20Center)!5e0!3m2!1sen!2sus!4v1710991568082!5m2!1sen!2sus"
                width="500"
                height="350"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Location;
