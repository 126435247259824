import * as React from "react";
import { useRef, useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import * as authServices from "../services/authService";
import { useForm } from "../hooks/UseForm";
import useAuth from "../hooks/UseAuth";
import { useNavigate, useLocation } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Star Automobiles
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

   const { setAuth, persist, setPersist } = useAuth();

   const navigate = useNavigate();
   const location = useLocation();
   const from = location.state?.from?.pathname || "/";

   const userRef = useRef();
   const errRef = useRef();

   const [user, setUser] = useState("");
   const [pwd, setPwd] = useState("");
   const [errMsg, setErrMsg] = useState("");
   const [success, setSuccess] = useState(false);
   const { handleError, newAlert } = useForm('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log({
      username: user,
      password: pwd,
    });

    try{
      const authResp = await authServices.loginUser(
        {
          user,
          pwd,
        }
      );

      console.log('auth Resp: ', authResp);

      const accessToken = authResp?.accessToken;
      const roles = authResp?.userInfo.role.split(',');
      const fullname = authResp?.userInfo.fullname;

      setAuth({user, roles, accessToken, fullname});
      setUser('');
      setPwd('');
      setSuccess(true);
      //navigate(from, {replace: true});
      navigate('/home', { replace: true });

    } catch (error){
      console.log('Error in Login: ', error.toString());
      handleError(error);
    }
  };
  
  /*
  useEffect(() => {
    localStorage.setItem("persist", persist);

  },[persist]);
  */
useEffect(() => {
  localStorage.setItem("persist", true);
}, []);


  const togglePersist = () => {
    setPersist(prev => !prev);
  }

 

  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            //noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="username"
              onChange={(e) => setUser(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
