import React from "react";
import PageHeader from "../components/layout/PageHeader";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DailyAccountForm from "../components/accounts/DailyAccountForm";

function DailyAccount() {
  return (
    <>
      <PageHeader
        title="Daily Workshop Collection and Account Tally"
        subTitle="Daily amount collection and "
        icon={<CurrencyRupeeIcon fontSize="medium" />}
      />
      <DailyAccountForm />
    </>
  );
}

export default DailyAccount;
