import React from "react";
import { useEffect, useState } from "react";
import * as purchaseServices from "../services/usePurchaseService";
import { useForm } from "../hooks/UseForm";
import { StyledBox } from "../components/controls/StyledBox";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Fab,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpenseForm from "../components/expense/ExpenseForm";
import useAxiosPrivate from "../hooks/UseAxiosPrivate";
import * as metadata from "../utils/metadata";
import { useLoading, Loading } from "../hooks/UseLoading";
import useAuth from "../hooks/UseAuth";


const initialValues = {
  date: "",
  description: "",
  amount: null,
  payment_source: "",
  expense_type: "",
  expense_category: "",
  purchase_id: "",
  payment_mode: "",
  paid_by: "",
};

function Expense() {
  const { values, setvalues, newAlert, handleError, handleChange, resetForm } =
    useForm(initialValues);
  const [expenses, setexpenses] = useState([]);
  const [query, setQuery] = useState("");
  const [openExpense, setOpenExpense] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { startLoading, stopLoading, isloading } = useLoading();
  const { auth } = useAuth();


  useEffect(() => {
    const fetchExpenses = async () => {
      try {

        startLoading();
        //const expenseResp = await purchaseServices.useFetchExpense();
        let expenseResp = [{}];
        
        console.log('User Auth ==> ', auth)
        //check the role
        if (auth && auth.roles.includes("account")) {
          expenseResp = (await axiosPrivate.get(metadata.EP_FETCH_EXPENSES_COMPANY)).data;
        } else if (auth && auth.roles.includes("admin")) {
          expenseResp = ( await axiosPrivate.get(metadata.EP_FETCH_EXPENSES)).data;
        } else {
          console.log('Fetching workshop only expenses');
          expenseResp = (await axiosPrivate.get(metadata.EP_FETCH_EXPENSES_WORKSHOP)).data;
        }
         
        //const expenseResp = response.data;
        console.log("Original Expenses: ", expenseResp);
        // fix date format
        var updatedExpense = expenseResp;
        for (var i = 0; i < expenseResp.length; i++) {
          //updatedExpense[i].date = dayjs(updatedExpense[i].date).format(
          //  "DD-MMM-YYYY"
          //);
          //updatedExpense[i].date = new Date(updatedExpense[i].date);
          updatedExpense[i].amount = "₹ " + updatedExpense[i].amount;
        }
        console.log("Expenses: ", updatedExpense);
        setexpenses(updatedExpense);
        stopLoading();
      } catch (error) {
        handleError(error);
      }
    };
    fetchExpenses().catch((error) => handleError(error));
  }, [openExpense]);



  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "DATE",
      width: 120,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (params) => dayjs(params?.value).format("DD-MMM-YYYY"),
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expense_type",
      headerName: "TYPE",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expense_category",
      headerName: "CATEGORY",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "payment_source",
      headerName: "SOURCE",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "payment_mode",
      headerName: "MODE",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "purchase_id",
      headerName: "ORDER ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paid_by",
      headerName: "PAID BY",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  ];

  const filteredList = (list) => {
    if (!query) {
      return list;
    }

    console.log("query: ", query);
    console.log("list: ", list);

    return list.filter(
      (item) =>
        item.description.includes(query) ||
        item.expense_type.includes(query) ||
        item.expense_category.includes(query) ||
        item.payment_source.includes(query) ||
        item.paid_by.includes(query) ||
        item.payment_mode.includes(query)
    );
  };
  const handleNewExpense = () => {
    setOpenExpense(true);
  };

  const handleCloseExpense = () => {
    // clear state
    setvalues(initialValues);
    setOpenExpense(false);
  };

  return (
    <>
      <Container sx={{ margin: 2 }}>
        <TextField
          sx={{ width: "50%" }}
          id="outlined-search"
          label="Search Expenses"
          type="search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Container>
      <StyledBox border={1}>
        <DataGrid rows={filteredList(expenses)} columns={columns}  />
      </StyledBox>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        onClick={handleNewExpense}
      >
        <AddIcon />
      </Fab>
      <Dialog open={openExpense} onClose={handleCloseExpense} fullScreen>
        <DialogContent>
          <ExpenseForm
            values={values}
            setvalues={setvalues}
            handleChange={handleChange}
            resetForm={resetForm}
            handleError={handleError}
            newAlert={newAlert}
            setOpenExpense={setOpenExpense}
          />
        </DialogContent>
      </Dialog>
      <Loading isloading={isloading} />
    </>
  );
}

export default Expense;
