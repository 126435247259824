import axios from "../api/axios";
import useAuth from "./UseAuth";
import { useNavigate, useLocation } from "react-router-dom";

const useRefeshToken = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = async () => {
    try {
      const response = await axios.get("/auth/v1/refreshToken", {
        withCredentials: true,
      });

      setAuth((prev) => {
        console.log("prev state => ", prev);
        console.log("access token =>", response.data.accessToken);
        return {
          ...prev,
          roles:
            response.data.userInfo.role &&
            response.data.userInfo.role.split(","),
          accessToken: response.data.accessToken,
          fullname: response.data.userInfo.fullname,
          username: response.data.userInfo.username,
        };
      });
      return response.data.accessToken;
    } catch (error) {
      // refresh token expired
      console.log("Refresh token expired");
      setAuth({});
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  return refresh;
};

export default useRefeshToken;
